import { GetTonnes } from '../GetTonnes'

export enum FactorUnits {
  "kg",
  "l",
  "litres",
  "tonnes"
}

export interface GetCo2eProps {
  factor: number
  count?: number
  weight_g?: number
  unit?: FactorUnits
}

export const GetCo2e = ({ factor, count = 1, weight_g = 1000, unit = FactorUnits.kg }: GetCo2eProps) => {
  if (unit === FactorUnits.tonnes) {
    return GetTonnes(count * weight_g) * factor
  }

  return ((count * weight_g) / 1000) * factor
}
