import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES } from 'clarity'

export const useStyles = () => {
  const { bp, fontBold, colors } = STYLES.useStyles()

  const container: ViewStyle = {
    marginBottom: 0
  }

  const text: TextStyle = {
    ...fontBold,
    color: colors.brand,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    textAlign: 'center'
  }

  if (bp.tablet) {
    text.fontSize = STYLES.fontSize.l
    text.lineHeight = STYLES.lineHeight.l
  }

  if (bp.desktop) {
    text.fontSize = STYLES.fontSize.xl
    text.lineHeight = STYLES.lineHeight.xl
  }

  const styles = StyleSheet.create({
    container,
    text
  })

  return { styles }
}
