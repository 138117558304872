import React, { memo } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

export const Logo = memo((props: SvgProps) => (
  <Svg viewBox="0 0 873.328 337.904" {...props}>
    <Path fill="none" d="M0 0h873.328v337.904H0z" pointerEvents="none" />
    <Path
      fill="#BAEA82"
      fillRule="nonzero"
      d="M259.55 114.982c24.196 23.493 39.258 56.22 39.258 92.474 0 5.541-.351 10.994-1.062 16.367 24.195-23.492 39.258-56.22 39.258-92.48 0-71.444-58.309-129.255-130.178-129.255-13.296 0-26.057 2.023-38.196 5.63 48.209 14.608 84.455 56.315 90.92 107.264"
    />
    <Path
      fill="#69DBE1"
      fillRule="nonzero"
      d="M39.508 223.816a125.601 125.601 0 0 1-1.063-16.36c0-36.254 15.07-68.981 39.259-92.474C84.176 64.04 120.429 22.32 168.718 7.717c-12.05-3.694-24.9-5.629-38.188-5.629C58.565 2.088.337 59.987.337 131.343c-.08 36.253 14.982 68.988 39.17 92.48"
    />
    <Path
      fill="#6ABC82"
      fillRule="nonzero"
      d="M77.623 114.982c23.478-22.795 55.56-36.78 91.007-36.78s67.53 14.073 90.92 36.78C253.085 64.04 216.839 22.32 168.63 7.717c-48.208 14.609-84.542 56.316-91.014 107.265"
    />
    <Path
      fill="#C74272"
      fillRule="nonzero"
      d="M168.63 254.968c-12.05 3.701-24.9 5.636-38.188 5.636-35.455 0-67.537-14.08-91.022-36.78 8.063 63.703 62.831 112.974 129.122 112.974 66.284 0 121.053-49.271 129.116-112.975-23.485 22.789-55.56 36.781-90.92 36.781-13.208 0-26.057-2.023-38.108-5.636"
    />
    <Path
      fill="#FA8B76"
      fillRule="nonzero"
      d="M259.55 114.982a124.41 124.41 0 0 1 1.07 16.36c0 58.25-38.731 107.441-92.078 123.626 12.05 3.701 24.9 5.636 38.189 5.636 35.454 0 67.53-14.08 90.927-36.78a124.524 124.524 0 0 0 1.062-16.368c.088-36.254-14.88-68.981-39.17-92.474"
    />
    <Path
      fill="#F89ED2"
      fillRule="nonzero"
      d="M168.63 254.968c-53.258-16.185-92.077-65.375-92.077-123.625 0-5.542.359-10.995 1.063-16.36-24.189 23.492-39.259 56.22-39.259 92.473 0 5.541.36 10.994 1.063 16.367 23.485 22.789 55.567 36.781 91.015 36.781 13.296 0 26.145-2.023 38.195-5.636"
    />
    <Path
      fill="#5F2B68"
      d="M168.63 254.968c53.259-16.185 92.078-65.375 92.078-123.625 0-5.542-.36-10.995-1.063-16.36C236.16 92.186 204.077 78.2 168.63 78.2s-67.53 14.074-91.014 36.781a125.685 125.685 0 0 0-1.063 16.36c0 58.163 38.819 107.441 92.077 123.626M457.25 104.486c9.822 0 18.442 2.008 25.853 6.003 7.403 3.995 13.113 9.236 17.107 15.73v-19.725h42.711v139.113l-.05 2.932c-.36 11.67-2.882 22.305-7.573 31.907-5.072 10.409-12.82 18.691-23.228 24.848-10.408 6.165-23.345 9.243-38.833 9.243-20.649 0-37.383-4.867-50.202-14.608-12.828-9.741-20.15-22.942-21.975-39.581h42.205l.41 1.422c1.51 4.654 4.545 8.385 9.082 11.185 4.992 3.079 11.156 4.618 18.479 4.618 8.825 0 15.861-2.536 21.11-7.616 5.24-5.08 7.864-13.193 7.864-24.35v-19.739l-1.297 1.906c-4.031 5.63-9.345 10.284-15.935 13.956-7.33 4.075-15.906 6.113-25.728 6.113-11.486 0-21.894-2.954-31.218-8.869-9.323-5.908-16.69-14.315-22.106-25.215-5.402-10.906-8.115-23.528-8.115-37.844 0-14.315 2.713-26.893 8.115-37.712 5.416-10.826 12.783-19.145 22.106-24.972 9.324-5.835 19.732-8.745 31.226-8.745h-.008Zm149.632 2.008v139.363h-42.704V106.494h42.704Zm63.967-45.46v102.15l42.213-56.69h52.701l-57.95 69.927 58.441 69.436h-52.943l-42.462-58.441v58.44h-42.71V61.036h42.71Zm144.64 45.46v139.363h-42.711V106.494h42.711Zm-345.756 35.213c-8.488 0-15.686 3.042-21.594 9.118-5.915 6.077-8.869 14.44-8.869 25.098 0 10.657 2.954 19.109 8.87 25.354 5.907 6.245 13.105 9.36 21.6 9.36 8.488 0 15.686-3.079 21.601-9.236 5.916-6.157 8.87-14.572 8.87-25.23 0-10.657-2.954-19.057-8.87-25.221-5.908-6.157-13.113-9.243-21.6-9.243h-.008Zm324.25-99.818c12.256 0 22.195 9.94 22.195 22.202 0 12.256-9.94 22.195-22.195 22.195-12.263 0-22.21-9.94-22.21-22.195 0-12.263 9.947-22.21 22.21-22.21v.008Zm-208.13 0c12.255 0 22.194 9.94 22.194 22.202 0 12.256-9.94 22.195-22.195 22.195-12.263 0-22.21-9.94-22.21-22.195 0-12.263 9.947-22.21 22.21-22.21v.008Z"
    />
    <Path
      fill="#5F2B68"
      d="M849.915 201.63c12.255 0 22.194 9.939 22.194 22.201 0 12.256-9.939 22.195-22.194 22.195-12.263 0-22.21-9.94-22.21-22.195 0-12.262 9.947-22.209 22.21-22.209v.007Z"
    />
  </Svg>
))
