import React, { useState, useMemo } from 'react'
import { View } from 'react-native'
import { NumberInput } from 'clarity'
import {
  HotelEmissionsData,
  HotelEmissionsDataKeyType
} from 'widgets/FlightWidget/data'
import { useSharedStyles } from 'styles'
import { Combobox, RemoveButton } from 'components'

interface HotelComponentProps {
  onLocationChange: (location: HotelEmissionsDataKeyType | undefined) => void
  onNightsChange: (nights: number) => void
  onRemoveHotel: () => void
}

const HotelComponent = ({
  onLocationChange,
  onNightsChange,
  onRemoveHotel
}: HotelComponentProps) => {
  const { sharedStyles, spacer } = useSharedStyles()
  const [_hotelNights, _setHotelNights] = useState<number>(1)

  const _hotelItems = useMemo(
    () =>
      Object.keys(HotelEmissionsData).map((key) => ({
        id: key,
        label: HotelEmissionsData[key as HotelEmissionsDataKeyType].name
      })),
    []
  )

  const handleLocationSelect = (id: string) => {
    onLocationChange(id as HotelEmissionsDataKeyType)
  }

  const handleNightsChange = (nights: number) => {
    _setHotelNights(nights)
    onNightsChange(nights)
  }

  return (
    <View
      testID="HotelComponent"
      style={[sharedStyles.box, { marginBottom: spacer }]}>
      <Combobox
        items={_hotelItems}
        label="Hotel Location"
        onSelect={handleLocationSelect}
      />
      <NumberInput
        label="Number of Nights"
        onChange={(value: number) => handleNightsChange(value)}
        value={_hotelNights}
        min={1}
      />
      <RemoveButton onPress={onRemoveHotel} />
    </View>
  )
}

export default HotelComponent
