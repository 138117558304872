export const GetNumericStringValue = (newValue: string, oldValue = '') => {
  const _validDecimalRegex = new RegExp(/^\d+(\.\d*)?$/g)
  const _zeroRegex = new RegExp(/^0\d+$/g)

  if (newValue === '') {
    newValue = ''
  }
  else if (newValue === '.') {
    newValue = '0.'
  }
  else if (_zeroRegex.test(newValue)) {
    newValue = parseInt(newValue, 10).toString()
  }
  else if (!_validDecimalRegex.test(newValue)) {
    newValue = oldValue.toString()
  }

  return newValue
}