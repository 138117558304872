import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { ClothingWidget } from 'widgets'

export const NewClothesScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - New Clothes Carbon Calculator'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Discover the carbon footprint of new clothes and how much you could save by buying second hand."
          />
        </Helmet>
        <Column.Container testID="NewClothesScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>New Clothes Carbon Calculator</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/clothes-clouds.svg')}
                style={{
                  width: 256,
                  height: 169
                }}
              />
            </Column.HiddenContent>
            <ClothingWidget
              heading="The clothing industry accounts for 10% of greenhouse gas emissions worldwide. To cut back try to only buy clothes if you think you'll use them at least 30 times."
              legend="Enter the clothes you're planning on buying and see the carbon and water needed to make them."
              result="Your wardrobe carbon footprint is **${emissions}kg** and will need **${water_l} litres** of water. That's the equivalent of driving **${evDrivingMiles} miles** in an electric car and enough water for **${showersMonths} months** of showers.\n\nBuying half of your clothes second hand would save **${emissions2ndHand}kg** of carbon."
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/clothes-clouds.svg')}
                style={{
                  width: 378,
                  height: 249
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text={`The carbon footprint of clothing can be quite different depending on the company you buy from. Look out for sustainable brands, organic materials or use our [greenwash checklist](https://zero.giki.earth/actions/greenwash-checklist?utm_source=new_clothes_carbon_calculator&utm_campaign=widgets) to help you out.`}
              widget="new_clothes_carbon_calculator"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Estimates are taken from Defra emissions factors adjusted for clothing weights and industry studies.\n\nDisclaimer: This holiday wardrobe calculator is to help people learn more about how everyday decisions may have an impact on the planet. However, all carbon footprint estimations are exactly that, estimates. We hope that in the long term companies will provide accurate carbon footprints of all the clothing they sell. This widget was made in March 2023 with data from that time. This widget is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
