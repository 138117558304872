import { useMemo } from 'react'
import EF from 'data/emission_factors.json'
import { GetOptions, DataProps } from 'utils'

export const OFFICE_CARBON_PER_DAY = ((160 * 12) / 365) * 0.2
export const HOME_CARBON_PER_DAY = 8 * 0.34075331486473
export const WEEKS_PER_YEAR = 52

const _labels = {
  boats_ferry_foot_passenger_passengerkm_kg_co2e: 'Ferry',
  car_dual_purpose_4x4_diesel_km_kg_co2e: 'Diesel 4X4',
  car_dual_purpose_4x4_petrol_km_kg_co2e: 'Petrol 4X4',
  car_dual_purpose_4x4_plug_in_hybrid_electric_vehicle_km_kg_co2e:
    'Plug-in hybrid 4X4',
  car_dual_purpose_4x4_battery_electric_vehicle_km_kg_co2e: 'Electric 4X4',
  car_small_car_diesel_km_kg_co2e: 'Small diesel car',
  car_small_car_petrol_km_kg_co2e: 'Small petrol car',
  car_small_car_hybrid_km_kg_co2e: 'Small hybrid car',
  car_small_car_plug_in_hybrid_electric_vehicle_km_kg_co2e:
    'Small plug-in hybrid car',
  car_small_car_battery_electric_vehicle_km_kg_co2e: 'Small EV car',
  car_medium_car_diesel_km_kg_co2e: 'Medium diesel car',
  car_medium_car_petrol_km_kg_co2e: 'Medium petrol car',
  car_medium_car_hybrid_km_kg_co2e: 'Medium hybrid car',
  car_medium_car_plug_in_hybrid_electric_vehicle_km_kg_co2e:
    'Medium plug-in hyrid car',
  car_medium_car_battery_electric_vehicle_km_kg_co2e: 'Medium EV car',
  car_large_car_petrol_km_kg_co2e: 'Large petrol car',
  car_large_car_diesel_km_kg_co2e: 'Large diesel car',
  car_large_car_hybrid_km_kg_co2e: 'Large hybrid car',
  car_large_car_plug_in_hybrid_electric_vehicle_km_kg_co2e:
    'Large plug-in hybrid car',
  car_large_car_battery_electric_vehicle_km_kg_co2e: 'Large EV car',
  motorbike_average_petrol_km_kg_co2e: 'Motorbike',
  taxis_taxis_regular_taxi_passengerkm_kg_co2e: 'Taxi',
  bus_bus_average_local_bus_passengerkm_kg_co2e: 'Bus',
  bus_bus_coach_passengerkm_kg_co2e: 'Coach',
  rail_rail_national_rail_passengerkm_kg_co2e: 'Train',
  rail_rail_light_rail_and_tram_passengerkm_kg_co2e: 'Light rail or tram',
  rail_rail_london_underground_passengerkm_kg_co2e:
    'Underground, metro or subway',
  bus_bus_electric_bus_passengerkm_kg_co2e: 'Electric bus',
  active_travel_walking_walking_kg_kg_co2e: 'Walking',
  active_travel_cycling_cycling_kg_kg_co2e: 'Cycling'
}

export const UnitOptions = [
  { label: 'km', value: '1' },
  { label: 'miles', value: '1.604' }
]

export const getData: () => DataProps = () => {
  return Object.keys(_labels).reduce((p, i) => {
    const item = EF[i as keyof typeof EF]

    return {
      ...p,
      [item.key]: {
        label: _labels[i as keyof typeof _labels],
        id: item.key,
        factor: item.conversion_factor
      }
    }
  }, {})
}

export const useData = () => {
  const data: DataProps = useMemo(() => {
    return getData()
  }, [])

  const options = useMemo(() => {
    return GetOptions(data)
  }, [data])

  return { data, options }
}
