import { useMemo } from 'react'
import EF from 'data/emission_factors.json'
import { GetOptions, DataProps } from 'utils'

const _category = 'Diet'
const _subCategory = 'Food'

export const getData: () => DataProps = () => {
  return Object.keys(EF).reduce((p, i) => {
    const item = EF[i as keyof typeof EF]

    if (item.category === _category && item.sub_category === _subCategory) {
      return {
        ...p,
        [item.key]: {
          label: item.sub_group,
          id: item.key,
          factor: item.conversion_factor
        }
      }
    }

    return p
  }, {})
}

export const useData = () => {
  const data: DataProps = useMemo(() => {
    return getData()
  }, [])

  const options = useMemo(() => {
    return GetOptions(data)
  }, [data])

  return { data, options }
}

export const appleCo2e = (80 / 1000) * EF.food_fruits_apples_kg_kg_co2e.conversion_factor
