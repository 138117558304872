import { useLayoutEffect } from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

export const NotFoundScreen = ({
  route,
  navigation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: NativeStackScreenProps<any>) => {
  useLayoutEffect(() => {
    if (route.path === '/giki-litter-carbon-calculator') {
      navigation.navigate('LitterPickerScreen')
    } else {
      navigation.navigate('HomeScreen')
    }
  }, [navigation, route.path])

  return null
}
