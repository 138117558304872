import React, { memo } from 'react'
import { Text, View, ViewProps } from 'react-native'
import { useStyles } from './styles'

export interface PrimaryHeadingProps extends ViewProps {
  children: React.ReactNode
}

export const PrimaryHeading = memo(
  ({ children, style, ...props }: PrimaryHeadingProps) => {
    const { styles } = useStyles()

    return (
      <View
        style={[styles.container, style]}
        testID="PrimaryHeading"
        {...props}>
        <Text
          style={styles.text}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          accessibilityRole="heading">
          {children}
        </Text>
      </View>
    )
  }
)
