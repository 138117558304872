import { useMemo } from 'react'
import { SortByLabel } from 'utils'

export interface DataProps {
  label: string
  value: string
}

export const getLightBulbData = () => {
  return [
    { label: 'Compact Fluorescent Lamp 6W', value: '6' },
    { label: 'Compact Fluorescent Lamp 9W', value: '9' },
    { label: 'Compact Fluorescent Lamp 12W', value: '12' },
    { label: 'Compact Fluorescent Lamp 15W', value: '15' },
    { label: 'Compact Fluorescent Lamp 20W', value: '20' },
    { label: 'Halogen 18W', value: '18' },
    { label: 'Halogen 28W', value: '28' },
    { label: 'Halogen 42W', value: '42' },
    { label: 'Halogen 53W', value: '53' },
    { label: 'Halogen 70W', value: '70' },
    { label: 'Incandescent 25W', value: '25' },
    { label: 'Incandescent 40W', value: '40' },
    { label: 'Incandescent 60W', value: '60' },
    { label: 'Incandescent 100W', value: '100' },
    { label: 'Incandescent 150W', value: '150' },
    { label: 'LED 4W', value: '4' },
    { label: 'LED 6W', value: '6' },
    { label: 'LED 10W', value: '10' },
    { label: 'LED 13W', value: '13' },
    { label: 'LED 18W', value: '18' }
  ]
}

export const getCountryData = () => {
  return [
    { label: 'UK', value: '193' },
    { label: 'Global', value: '442' },
    { label: 'Australia', value: '780' },
    { label: 'New Zealand', value: '171' },
    { label: 'India', value: '720' },
    { label: 'Canada', value: '248' },
    { label: 'Switzerland', value: '59' },
    { label: 'Brazil', value: '215' },
    { label: 'Germany', value: '391' },
    { label: 'Spain', value: '253' },
    { label: 'France', value: '106' },
    { label: 'Sweden', value: '69' },
    { label: 'Norway', value: '65' },
    { label: 'Finland', value: '174' },
    { label: 'Denmark', value: '252' },
    { label: 'Belgium', value: '177' },
    { label: 'Netherlands', value: '519' },
    { label: 'Italy', value: '269' },
    { label: 'China', value: '528' },
    { label: 'Japan', value: '408' },
    { label: 'South Africa', value: '889' },
    { label: 'Singapore', value: '408' },
    { label: 'Hong Kong', value: '640' },
    { label: 'Estonia', value: '785' },
    { label: 'Indonesia', value: '717' },
    { label: 'Latvia', value: '189' },
    { label: 'Lithuania', value: '230' },
    { label: 'Luxembourg', value: '204' },
    { label: 'Malaysia', value: '503' },
    { label: 'Morocco', value: '589' },
    { label: 'Philippines', value: '691' },
    { label: 'Saudi Arabia', value: '614' }
  ]
}

export const useData = () => {
  const lightBulbOptions: DataProps[] = useMemo(() => {
    return getLightBulbData()
  }, [])

  const countryOptions = useMemo(() => {
    return SortByLabel(getCountryData()) as DataProps[]
  }, [])

  return {
    lightBulbOptions,
    countryOptions
  }
}