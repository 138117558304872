import { HotelEmissionsData } from 'widgets/FlightWidget/data'

type HotelEmissionsDataMap = {
  [key: string]: {
    name: string
    value: number
  }
}

interface HotelEmissionsParams {
  location: string
  nights: number
}

export const GetHotelEmissions = ({
  location,
  nights
}: HotelEmissionsParams): number => {
  if (!location || isNaN(nights) || nights <= 0) {
    return 0
  }

  const hotelEmissions =
    (HotelEmissionsData as HotelEmissionsDataMap)[location]?.value || 0.0
  return nights * hotelEmissions
}
