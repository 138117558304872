import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES } from 'clarity'

export const useStyles = () => {
  const { fontRegular, colors, spacing, bp } = STYLES.useStyles()

  const text: TextStyle = {
    ...fontRegular,
    color: colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    marginBottom: spacing.m,
    textAlign: 'center'
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    text.fontSize = STYLES.fontSize.m
    text.lineHeight = STYLES.lineHeight.m
  }

  const styles = StyleSheet.create({
    text
  })

  return { styles }
}
