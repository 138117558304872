import React, { memo } from 'react'
import { View } from 'react-native'
import { useStyles } from './styles'
import { Text } from 'clarity'

export const Footer = memo(() => {
  const { styles } = useStyles()

  return (
    <View style={styles.container} testID="Footer">
      <Text
        containerStyle={styles.textContainer}
        textStyle={
          styles.text
        }>{`© 2020-${new Date().getFullYear()} Giki. Think Carbon. Cut Carbon.`}</Text>
      <Text
        containerStyle={[styles.textContainer, styles.links]}
        textStyle={
          styles.text
        }>{`[Terms](https://giki.earth/terms-of-use/?utm_source=footer&utm_campaign=widgets)      [Privacy](https://giki.earth/privacy-policy/?utm_source=footer&utm_campaign=widgets)`}</Text>
    </View>
  )
})
