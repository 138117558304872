import { DefaultTheme } from '@react-navigation/native'
import { STYLES } from 'clarity'

export const useNavigationStyles = () => {
  const { colors } = STYLES.useStyles()

  return {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: colors.brand,
      background: colors.background,
      card: colors.background,
      text: colors.text,
      border: colors.navBorder,
      notification: colors.brand
    }
  }
}
