import React, { memo, useRef } from 'react'
import {
  NavigationContainer,
  createNavigatorFactory,
  useNavigationBuilder,
  StackRouter,
  NavigationHelpersContext,
  createNavigationContainerRef,
  getStateFromPath,
  getActionFromState
} from '@react-navigation/native'
import { NavigationConfig } from './config'
import { useNavigationStyles } from './useNavigationStyles'
import * as Linking from 'expo-linking'
import * as Screens from 'screens'
import { StyleSheet, View, Animated, ScrollView, Platform } from 'react-native'
import {
  STYLES,
  NavigationContext,
  AnalyticsContext,
  DefaultAnalyticsProvider,
  ConfigurationContextProvider,
  DefaultConfiguration
} from 'clarity'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { TopBar } from './TopBar'
import { Footer } from './Footer'
import * as WebBrowser from 'expo-web-browser'

const navigationRef = createNavigationContainerRef()

interface CustomNavigatorProps {
  children: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  screenOptions?: any
  initialRouteName?: string
}

const CustomNavigator = memo(
  ({ children, screenOptions, initialRouteName }: CustomNavigatorProps) => {
    const { state, navigation, descriptors } = useNavigationBuilder(
      StackRouter,
      {
        children,
        screenOptions,
        initialRouteName
      }
    )

    const _offsetY = useRef(new Animated.Value(1)).current
    const scrollViewRef = useRef<ScrollView>()
    const _currentRoute = state.routes[state.index]
    const insets = useSafeAreaInsets()
    const { sizing, spacing, bp } = STYLES.useStyles()

    const { showBackButton = true } = descriptors[_currentRoute.key].options

    return (
      <NavigationHelpersContext.Provider value={navigation}>
        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <TopBar offsetY={_offsetY} showBackButton={showBackButton} />
          <View style={{ flex: 1 }}>
            <Animated.ScrollView
              contentContainerStyle={{
                paddingTop: sizing.topBarHeight + insets.top,
                marginTop: bp.not([bp.tablet, bp.desktop]) ? spacing.xl : 0
              }}
              keyboardShouldPersistTaps="handled"
              keyboardDismissMode="none"
              scrollEnabled={true}
              ref={scrollViewRef}
              onScroll={Animated.event(
                [
                  {
                    nativeEvent: {
                      contentOffset: {
                        y: _offsetY
                      }
                    }
                  }
                ],
                {
                  useNativeDriver: Platform.OS !== 'web'
                }
              )}
              scrollEventThrottle={16}>
              {state.routes.map((route, i) => {
                return (
                  <View
                    key={route.key}
                    style={[
                      {
                        flexGrow: 1,
                        paddingLeft: spacing.page.padding.paddingLeft,
                        paddingRight: spacing.page.padding.paddingRight
                      },
                      { display: i === state.index ? 'flex' : 'none' }
                    ]}>
                    <View
                      style={{
                        flex: 1,
                        maxWidth: STYLES.grid.maxContentWidth,
                        width: '100%',
                        alignSelf: 'center',
                        alignItems: 'center'
                      }}>
                      {descriptors[route.key].render()}
                    </View>
                  </View>
                )
              })}
              <Footer />
            </Animated.ScrollView>
          </View>
        </View>
      </NavigationHelpersContext.Provider>
    )
  }
)

const createCustomNavigator = createNavigatorFactory(CustomNavigator)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Stack = createCustomNavigator<any>()

export const Navigation = () => {
  const NavigationStyles = useNavigationStyles()

  const _linking = {
    prefixes: [Linking.createURL('/')],
    config: NavigationConfig
  }

  const navigationContextProviderValue = {
    navigate: async (uri: string) => {
      if (uri?.indexOf('http') === -1) {
        const _state = getStateFromPath(uri, NavigationConfig)
        if (_state) {
          const _action = getActionFromState(_state)

          if (_action && navigationRef?.isReady()) {
            navigationRef.dispatch(_action)
            return
          }
        }
      }

      await WebBrowser.openBrowserAsync(uri)
    },
    transform: (uri = '') => {
      return uri
    },
    getCurrentRoute: () => {
      return ''
    }
  }

  const analyticsContextProviderValue = {
    ...DefaultAnalyticsProvider,
    trackEvent: () => {
      //
    }
  }

  const configurationContextProviderValue = {
    ...DefaultConfiguration,
    ClientApplication: 'comparisons'
  }

  return (
    <ConfigurationContextProvider value={configurationContextProviderValue}>
      <AnalyticsContext.Provider value={analyticsContextProviderValue}>
        <NavigationContext.Provider value={navigationContextProviderValue}>
          <NavigationContainer
            ref={navigationRef}
            theme={NavigationStyles}
            linking={_linking}>
            <Stack.Navigator
              initialRouteName="Home"
              screenOptions={{
                headerShown: false,
                animation: 'none'
              }}>
              {Object.keys(Screens).map((screen) => (
                <Stack.Screen
                  key={screen}
                  name={screen}
                  component={Screens[screen as never]}
                  options={{
                    showBackButton: screen === 'HomeScreen' ? false : true
                  }}
                />
              ))}
            </Stack.Navigator>
          </NavigationContainer>
        </NavigationContext.Provider>
      </AnalyticsContext.Provider>
    </ConfigurationContextProvider>
  )
}

export { NavigationConfig }
