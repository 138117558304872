import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'clarity'

export const useStyles = () => {
  const { spacing, bp, fontRegular, fontSemiBold, colors } = STYLES.useStyles()

  const container: ViewStyle = {
    maxWidth: getREM(35.25),
    width: '100%',
    marginTop: spacing.l,
    alignSelf: 'center'
  }

  const linkContainer: ViewStyle = {
    ...StyleSheet.absoluteFillObject
  }

  const link: ViewStyle = {
    ...StyleSheet.absoluteFillObject,
    top: getREM(0.25),
    left: getREM(0.25),
    right: getREM(0.25),
    bottom: getREM(0.25),
    borderRadius: getREM(0.125)
  }

  const labelContainer: ViewStyle = {
    flex: 1,
    marginRight: spacing.xs,
    flexWrap: 'wrap'
  }

  const text: TextStyle = {
    ...fontSemiBold,
    textTransform: 'capitalize',
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand
  }

  const subLabel: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs,
    color: colors.text
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    text.fontSize = STYLES.fontSize.normal
    text.lineHeight = STYLES.lineHeight.normal
    subLabel.fontSize = STYLES.fontSize.s
    subLabel.lineHeight = STYLES.lineHeight.s
  }

  const styles = StyleSheet.create({
    container,
    linkContainer,
    link,
    labelContainer,
    text,
    subLabel
  })

  return { styles }
}
