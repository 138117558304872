import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, PrimaryHeading, WhatNext } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { WorkHomeWidget } from 'widgets/WorkHomeWidget'

export const WorkHomeScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Working from home carbon carbon calculator'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Find out the carbon emissions of working from home and compare them to commuting and working in the office."
          />
        </Helmet>
        <Column.Container testID="CommutingScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>Working from home carbon calculator</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/house.svg')}
                style={{
                  width: 256,
                  height: 208
                }}
              />
            </Column.HiddenContent>
            <WorkHomeWidget
              heading="Find out the carbon emissions of working from home and compare them to commuting and working in the office."
              intro="First enter details about your home working. Then add commuting, one way only. You can add more stages if your commute involves different types of transport."
              legend="Commuting stage"
              result="Working from home for a day creates **2.7kg** of carbon whilst going to the office is **${co2eTotal}kg**. Over the year working from home could lead to **${co2eTotalYear}kg** of carbon savings.\n\n<small>*We're all different but whether you are working from home or commuting there are ways to cut carbon. When working from home you can use energy saving measures to cut your carbon footprint. Switching to renewable energy will help even more. If you are going to the office walking and cycling are best and then public transport. Do what you can to save energy in the office too.*</small>"
              alternativeResult="Working from home for a day creates **2.7kg** of carbon whilst going to the office is **${co2eTotal}kg**. There are many ways to reduce working from home emissions from switching to renewable electricity to saving on heating bills. Use Giki's working from home checklist as a starting point\n\n<small>*We're all different but whether you are working from home or commuting there are ways to cut carbon. When working from home you can use energy saving measures to cut your carbon footprint. Switching to renewable energy will help even more. If you are going to the office walking and cycling are best and then public transport. Do what you can to save energy in the office too.*</small>"
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/house.svg')}
                style={{
                  width: 378,
                  height: 307
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text="Learn more about how we support companies on their Net Zero journey."
              widget="working_from_home_carbon_calculator"
              href={
                'https://giki.earth/pro/?utm_source=widget_working_from_home_carbon_calculator&utm_campaign=widgets'
              }
              label="Learn more"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Emissions factors for home working and commuting come from Defra 2022. By entering distance one way we then calculate the return commute. Estimates for the year are based on assumption of same level of commute, each week, for the year. Office carbon footprints are taken from the Green Council building Code kwhe assuming 12m2 per person and UK grid factors using Defra.\n\nDisclaimer: This widget is to help people learn more about how everyday decisions may have an impact on the planet. However, all carbon footprint estimates are exactly that, estimates. We hope that in the long term you'll be able to find out the carbon footprint of every journey you make and the exact emissions from working from home. This mini carbon calculator was made in November 2023 with data from that time. This mini carbon calculator is provided on a fair use basis, if you chose to share the information please do citi Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
