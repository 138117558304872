import { StyleSheet, Platform } from 'react-native'
import { STYLES, getREM } from 'clarity'

export const useStyles = () => {
  const { input, boxShadows, COLORS, spacing, fontBold } = STYLES.useStyles()

  const styles = StyleSheet.create({
    dropdown: {
      ...input,
      ...boxShadows.button.web,
      ...boxShadows.button.android,
      ...boxShadows.button.ios,
      ...Platform.select({
        web: {
          cursor: 'pointer'
        }
      }),
      padding: 0
    },
    selectedTextStyle: {
      color: input.color,
      fontFamily: input.fontFamily,
      fontSize: input.fontSize,
      lineHeight: input.lineHeight
    },
    inputSearchStyle: {
      height: getREM(3.625),
      fontSize: STYLES.fontSize.normal,
      lineHeight: STYLES.lineHeight.normal,
      borderRadius: getREM(1.25)
    },
    list: {
      alignSelf: 'stretch',
      maxWidth: input.maxWidth,
      maxHeight: getREM(15),
      backgroundColor: COLORS.paper,
      borderRadius: getREM(1.25),
      marginTop: spacing.s,
      marginBottom: spacing.s,
      ...boxShadows.button.all,
      overflow: 'hidden',
      flexShrink: 0
    },
    listItem: {
      ...fontBold,
      fontSize: STYLES.fontSize.normal,
      lineHeight: STYLES.lineHeight.normal,
      textAlign: 'left',
      color: COLORS.aubergine
    }
  })

  return { styles }
}
