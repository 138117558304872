import React, { memo } from 'react'
import { useStyles } from './styles'
import { SpeechBubble, Button, ButtonType, View, STYLES, Text } from 'clarity'
import { ImageBackground } from 'react-native'

export interface WhatNextProps
  extends Partial<React.ComponentProps<typeof SpeechBubble>> {
  widget?: string
  href?: string
  label?: string
}

export const WhatNext = memo(
  ({ widget = 'unknown', href, children, ...props }: WhatNextProps) => {
    const { styles } = useStyles()
    const { spacing, bp } = STYLES.useStyles()

    return (
      <SpeechBubble categoryLabel="What next?" testID="WhatNext" {...props}>
        {children}
        <Button
          href={
            href ||
            `https://zero.giki.earth?utm_source=widget_${widget}&utm_campaign=widgets`
          }
          label={props.label || 'Sign up to Giki Zero'}
          type={ButtonType.brand}
          containerStyle={styles.buttonContainer}
        />
        <View
          spacer={bp.is([bp.tablet, bp.desktop]) ? spacing.xs : spacing.s}
          style={styles.buttonGroup}>
          <Button
            textStyle={styles.storeButton}
            type={ButtonType.blank}
            containerStyle={styles.storeButtonContainer}
            href={`https://play.google.com/store/apps/details?id=earth.giki.zero&utm_source=${widget}&utm_campaign=widgets`}
            label="Get it on Google Play">
            <ImageBackground
              source={require('./google.svg')}
              style={styles.storeButtonImage}
            />
          </Button>
          <Button
            textStyle={styles.storeButton}
            type={ButtonType.blank}
            containerStyle={styles.storeButtonContainer}
            href="https://apps.apple.com/app/apple-store/id1615016031?pt=119065048&ct=widgets&mt=8"
            label="Download on the App Store">
            <ImageBackground
              source={require('./apple.svg')}
              style={styles.storeButtonImage}
            />
          </Button>
        </View>
        <Text
          containerStyle={
            styles.feedbackTextContainer
          }>{`How could we make this widget better?\n[Let us know](https://airtable.com/shrOzmo2ux5K7AW8c)`}</Text>
      </SpeechBubble>
    )
  }
)
