import { ViewStyle } from 'react-native'
import { STYLES, getREM } from 'clarity'

export const useBoxStyles = () => {
  const { bp, spacing, COLORS } = STYLES.useStyles()

  const box: ViewStyle = {
    borderWidth: getREM(0.0625),
    borderStyle: 'solid',
    borderColor: COLORS.alto,
    padding: spacing.normal,
    borderRadius: getREM(1),
    flexBasis: 'auto'
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    box.paddingVertical = spacing.l
    box.paddingHorizontal = spacing.xl
  }

  return {
    box
  }
}