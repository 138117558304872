import React, { memo } from 'react'
import { View } from 'react-native'
import { useStyles } from './styles'
import { Text } from 'clarity'

export interface ResultProps {
  children?: React.ReactNode
}

export const Result = memo(({ children }: ResultProps) => {
  const { styles } = useStyles()

  return (
    <View style={styles.container} testID="Result">
      <View style={styles.content}>
        <Text containerStyle={styles.textContainer} textStyle={styles.text}>
          {children}
        </Text>
      </View>
    </View>
  )
})
