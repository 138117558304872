import EF from 'data/emission_factors.json'
import { FormatNumber } from '../FormatNumber'

export const fridge_kwh_pa = 116

export const GetFridgeYears = (emissions: number) => {
  return FormatNumber(
    (emissions * 365) /
    (fridge_kwh_pa *
      EF.electricity_uk_grid_domestic_electricity_kwh_kg_co2e
        .conversion_factor)
  )
}
