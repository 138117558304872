import EF from 'data/emission_factors.json'
import AirportData from './airports-iata.json'
import HotelEmissionsData from "./hotel-emissions.json"
import { GetMeanAverage } from 'utils'

export const TravelClassData = {
  'economy': {
    label: 'Economy',
    id: 'economy',
    short: EF.flights_short_haul_economy_class_passengerkm_kg_co2e.conversion_factor,
    long: EF.flights_long_haul_economy_class_passengerkm_kg_co2e.conversion_factor
  },
  'premium_economy': {
    label: 'Premium Economy',
    id: 'premium_economy',
    short: GetMeanAverage(
      EF.flights_short_haul_economy_class_passengerkm_kg_co2e.conversion_factor,
      EF.flights_short_haul_business_class_passengerkm_kg_co2e.conversion_factor
    ),
    long: EF.flights_long_haul_premium_economy_class_passengerkm_kg_co2e.conversion_factor
  },
  'business': {
    label: 'Business',
    id: 'business',
    short: EF.flights_short_haul_business_class_passengerkm_kg_co2e.conversion_factor,
    long: EF.flights_long_haul_business_class_passengerkm_kg_co2e.conversion_factor
  },
  'first': {
    label: 'First',
    id: 'first',
    short: EF.flights_short_haul_business_class_passengerkm_kg_co2e.conversion_factor,
    long: EF.flights_long_haul_first_class_passengerkm_kg_co2e.conversion_factor
  }
}

export type AirportDataKeyType = keyof typeof AirportData
export type HotelEmissionsDataKeyType = keyof typeof HotelEmissionsData

export { AirportData, HotelEmissionsData }