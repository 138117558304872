import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { LitterWidget } from 'widgets'

export const RecyclingScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - Recycling Carbon Calculator'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Discover how your recycling makes a difference. Find out the carbon savings from the packaging you recycle."
          />
        </Helmet>
        <Column.Container testID="RecyclingScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>Recycling Carbon Calculator</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/keyman-clouds.svg')}
                style={{
                  width: 256,
                  height: 202
                }}
              />
            </Column.HiddenContent>
            <LitterWidget
              heading="Discover how your recycling makes a difference. Find out the carbon savings from the packaging you recycle."
              legend="Try looking at your recycling container as you fill in the numbers. It's a good way to remind yourself of everything you've done."
              result="Your recycling saves **${emissionsSaved}kg** of carbon. This is because new packaging does not need to be made from virgin resources and you're not sending rubbish to landfill.\n\nIf you kept that up for a year, you'd save enough energy to power a fridge for **${fridgeYears} years!**\n\n<small>*All recycling is important but recycling cans and tins saves the most carbon as metal takes so much energy to make. It's also really important to recycle all your paper and cardboard because if it's sent to landfill it releases methane, a powerful greenhouse gas. If you can ditch the plastic altogether as it's harder to recycle all of it and the savings are smaller.*</small>"
              excludeItems={['other_items']}
              hasDisposal
              hasDays
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/keyman-clouds.svg')}
                style={{
                  width: 378,
                  height: 299
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text={`Help the planet by [recycling everything](https://zero.giki.earth/actions/weekly-recycler?utm_source=recycling_carbon_calculator&utm_campaign=widgets) you can.`}
              widget="recycling_carbon_calculator"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: This mini carbon calculator uses Defra conversion factors and calculates the carbon saved by not sending recycling to landfill as well as the carbon saved by using recycled materials rather than virgin materials for new packaging. Whilst this may benefit the person using the recycled content you've done a lot of the hard work! Weights are based on company website for packaging materials and Giki measurements.\n\nDisclaimer: This recycling carbon calculaor is to help people learn more about how everyday food decisions can have an impact on the planet. However, all carbon footprint estimations are exactly that, estimates.We hope that in the long term companies will provide low carbon packaging that is easy to recycle to, by default, nothing goes to landfill. This min carbon calculator was made in April 2023 with data from that time. This mini carbon calculator is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
