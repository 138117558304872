import React, { memo, useState, useEffect, useCallback } from 'react'
import { View } from 'react-native'
import { Intro, Result, Combobox, RemoveButton, SubHeading } from 'components'
import { Button, ButtonType, GenerateId, FieldSet, TextInput } from 'clarity'
import { useData, appleCo2e } from './data'
import { useSharedStyles } from 'styles'
import { GetNumericStringValue, FormatNumber, GetCo2e, InterpolateString } from 'utils'
import { WidgetProps } from '../index'

interface IngredientProps {
  id: string
  key: string
  weight: string
}

class Ingredient implements IngredientProps {
  id: string
  key = ''
  weight = ''

  constructor() {
    this.id = GenerateId()
  }
}

export const RecipeWidget = memo(({ heading, intro, legend = '', result }: WidgetProps) => {
  const { sharedStyles, spacer } = useSharedStyles()
  const { data, options } = useData()
  const [_ingredients, _setIngredients] = useState<IngredientProps[]>([new Ingredient()])
  const [_result, _setResult] = useState('')

  const _addIngredient = () => {
    _setIngredients((prevState) => ([
      ...prevState,
      new Ingredient()
    ]))
  }

  const _removeIngredient = (id: string) => {
    _setIngredients((prevState) => {
      const newState = [...prevState]

      for (let i = newState.length - 1; i >= 0; i--) {
        if (newState[i].id === id) {
          newState.splice(i, 1)
          break
        }
      }

      return newState
    })
  }

  const _updateIngredient = (id: string, field: keyof IngredientProps, value: string) => {
    _setIngredients((prevState) => {
      const newState = [...prevState]

      for (let i = 0; i < newState.length; i++) {
        if (newState[i].id === id) {
          newState[i][field] = field === 'weight' ? GetNumericStringValue(value, newState[i][field]) : value
          break
        }
      }

      return newState
    })
  }

  const _updateResult = useCallback(() => {
    const _co2eGrouped: {
      [key: string]: {
        co2e: number
        label: string
      }
    } = {}

    const _co2eTotal = _ingredients.reduce((p, i) => {
      if (!i.key || !i.weight) return p

      const _item = data[i.key]
      const _weight = parseFloat(i.weight)

      const _co2eItem = GetCo2e({
        factor: _item.factor,
        weight_g: _weight
      })

      if (_co2eGrouped[i.key]) {
        _co2eGrouped[i.key].co2e += _co2eItem
      } else {
        _co2eGrouped[i.key] = {
          co2e: _co2eItem,
          label: _item.label
        }
      }

      return p + _co2eItem
    }, 0)

    const _co2eLargest = Object.values(_co2eGrouped).sort((item1, item2) => {
      if (item1.co2e < item2.co2e) return 1
      if (item1.co2e > item2.co2e) return -1
      return 0
    })[0] || undefined

    const appleCount = FormatNumber(Math.round(_co2eTotal / appleCo2e), 0)

    if (_co2eLargest?.label) {
      const co2eLargestPercent = FormatNumber((_co2eLargest.co2e / _co2eTotal) * 100, 0)

      _setResult(
        InterpolateString(result, {
          co2eTotal: FormatNumber(_co2eTotal),
          appleCount,
          co2eLargestLabel: _co2eLargest.label,
          co2eLargestPercent
        })
      )
    } else {
      _setResult('')
    }
  }, [_ingredients, data, result])

  useEffect(() => {
    _updateResult()
  }, [_ingredients, _updateResult])

  return (
    <View testID="RecipeWidget" style={{ width: '100%' }}>
      {!!heading && <SubHeading>{heading}</SubHeading>}
      {!!intro && <Intro>{intro}</Intro>}
      {_ingredients.map((item, index) => (
        <FieldSet legend={legend} key={item.id} style={[sharedStyles.box, { marginBottom: spacer }]}>
          <Combobox
            items={options}
            label="Ingredient"
            onSelect={(value: string) => {
              _updateIngredient(item.id, 'key', value)
            }}
            testID="RecipeIngredient"
          />
          <TextInput
            label="Weight (g or ml)"
            onChangeText={(value: string) => {
              _updateIngredient(item.id, 'weight', value)
            }}
            type="numeric"
            value={item.weight}
            containerStyle={{ marginBottom: 0 }}
          />
          {index !== 0 && (
            <RemoveButton onPress={() => {
              _removeIngredient(item.id)
            }} />
          )}
        </FieldSet>
      ))}
      <Button
        label="Add ingredient"
        type={ButtonType.brand}
        onPress={_addIngredient}
      />
      {!!_result && <Result>{_result}</Result>}
    </View>
  )
})
