import EF from 'data/emission_factors.json'
import waterData from 'data/water.json'

const data = [
  EF.clothing_clothing_underwear_item_kg_co2e,
  EF.clothing_clothing_t_shirt_item_kg_co2e,
  EF.clothing_clothing_shirt_item_kg_co2e,
  EF.clothing_clothing_tracksuit_item_kg_co2e,
  EF.clothing_clothing_trousers_item_kg_co2e,
  EF.clothing_clothing_shorts_item_kg_co2e,
  EF.clothing_clothing_jumper_item_kg_co2e,
  EF.clothing_clothing_hoodie_item_kg_co2e,
  EF.clothing_clothing_jacket_item_kg_co2e,
  EF.clothing_clothing_hat_item_kg_co2e,
  EF.clothing_clothing_swimming_costume_item_kg_co2e,
  EF.clothing_clothing_top_item_kg_co2e,
  EF.clothing_clothing_dress_item_kg_co2e,
  EF.clothing_clothing_leggings_item_kg_co2e,
  EF.clothing_clothing_skirt_item_kg_co2e,
  EF.clothing_clothing_sandals_item_kg_co2e,
  EF.clothing_clothing_shoes_item_kg_co2e,
  EF.clothing_clothing_other_item_kg_co2e
]

data.sort((item1, item2) => {
  if (item1.key === 'clothing_clothing_other_item_co2e') return 1

  if (item1.sub_group > item2.sub_group) return 1
  if (item1.sub_group < item2.sub_group) return -1
  return 0
})

export { data, waterData }