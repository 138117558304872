import React, { memo } from 'react'
import { View } from 'react-native'
import { useStyles } from './styles'
import { Text } from 'clarity'

export interface FootnoteProps {
  children?: React.ReactNode
}

export const Footnote = memo(({ children }: FootnoteProps) => {
  const { styles } = useStyles()

  return (
    <View style={styles.container} testID="Footnote">
      <Text containerStyle={styles.textContainer} textStyle={styles.text}>
        {children}
      </Text>
    </View>
  )
})
