import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { ClothingWidget } from 'widgets'

export const SecondHandClothesScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - Second Hand Clothes Carbon Calculator'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Buying second hand clothes can be a big carbon saver."
          />
        </Helmet>
        <Column.Container testID="SecondHandClothesScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>
              Second Hand Clothes Carbon Calculator
            </PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/clothes-clouds.svg')}
                style={{
                  width: 256,
                  height: 169
                }}
              />
            </Column.HiddenContent>
            <ClothingWidget
              heading="Buying second hand clothes can be a big carbon saver."
              legend="Enter the clothes you've purchased to see how much carbon and water you can save."
              result="Buying these clothes second hand saved **${emissions}kg** of carbon and **${water_l} litres** of water. That's the equivalent of **${showersMonths} months** of showers.\n\nAs well as saving carbon, buying second hand is great for saving money too."
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/clothes-clouds.svg')}
                style={{
                  width: 378,
                  height: 249
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text="Interested in discovering more about your clothes footprint and lots more? Sign up to Giki Zero to learn what you can do for the planet."
              widget="second_hand_clothes_carbon_calculator"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Estimates are taken from Defra emissions factors adjusted for clothing weights and industry studies. Assumes all of clothing footprint accrues to the buyer of new clothes and no rebound effect.\n\nDisclaimer: This second hand carbon calculator is to help people learn more about how everyday decisions may have an impact on the planet. However, all carbon footprint estimates are exactly that, estimates.We hope that in the long term companies will provide accurate carbon footprints of all the clothing they sell. This widget was made in March 2023 with data from that time. This widget is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
