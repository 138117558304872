import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'clarity'

export const useStyles = () => {
  const { spacing, bp } = STYLES.useStyles()

  const container: ViewStyle = {
    marginTop: spacing.xxxl,
    maxWidth: getREM(67.5),
    alignSelf: 'center'
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    container.marginTop = spacing.xxxxl
  }

  const textContainer: TextStyle = {
    marginBottom: spacing.normal,
    textAlign: 'center'
  }

  const text: TextStyle = {
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    text.fontSize = STYLES.fontSize.s
    text.lineHeight = STYLES.lineHeight.s
  }

  const styles = StyleSheet.create({
    container,
    textContainer,
    text
  })

  return { styles }
}
