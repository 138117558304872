import React, { memo, useEffect } from 'react'
import { View } from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { NavigationConfig } from 'navigation'
import { PrimaryHeading, SubHeading } from 'components'
import { Helmet } from 'react-helmet'
import { List } from './List'
import { STYLES } from 'clarity'

export const HomeScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { spacing } = STYLES.useStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons'
      })
    }, [navigation])

    const items = [
      {
        label: 'Commuting',
        subLabel: 'Work out the carbon footprint of your commute',
        href: NavigationConfig.screens.CommutingScreen
      },
      {
        label: 'Working from home',
        subLabel:
          'Compare the emissions of working from home vs going to the office',
        href: NavigationConfig.screens.WorkHomeScreen
      },
      {
        label: 'Savings over the life of an EV',
        subLabel: 'How much can you save by going electric',
        href: NavigationConfig.screens.EVLifeScreen
      },
      {
        label: 'Food waste',
        subLabel: 'See the carbon wasted from throwing out food',
        href: NavigationConfig.screens.FoodWasteScreen
      },
      {
        label: 'Holiday flights',
        subLabel:
          'Carbon emissions from return flights to anywhere in the world',
        href: NavigationConfig.screens.HolidayFlightScreen
      },
      {
        label: 'Savings from turning the lights off',
        subLabel: 'See what you can save from this simple action',
        href: NavigationConfig.screens.LightsOffScreen
      },
      {
        label: 'Litter picking savings',
        subLabel: 'See the benefit of litter picking and recycling',
        href: NavigationConfig.screens.LitterPickerScreen
      },
      {
        label: 'New clothes',
        subLabel: 'The carbon impact of buying new items',
        href: NavigationConfig.screens.NewClothesScreen
      },
      {
        label: 'Recipe checker',
        subLabel: 'Find out the carbon footprint of a meal',
        href: NavigationConfig.screens.RecipeScreen
      },
      {
        label: 'Recycling',
        subLabel: 'Discover how your recycling makes a difference',
        href: NavigationConfig.screens.RecyclingScreen
      },
      {
        label: 'Second hand clothes',
        subLabel: 'Buying second hand clothes can be a big carbon saver',
        href: NavigationConfig.screens.SecondHandClothesScreen
      },
      {
        label: 'Work trips',
        subLabel: 'The carbon footprint of travelling for business',
        href: NavigationConfig.screens.WorkFlightScreen
      }
    ]

    const sortedItems = items
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label))

    return (
      <View testID="HomeScreen">
        <Helmet>
          <meta
            name="description"
            content="Compare the carbon footprints of everyday choices. Look up recipes, flights, new clothes and more."
          />
        </Helmet>
        <PrimaryHeading style={{ marginBottom: spacing.l }}>
          Giki Mini Carbon Calculators
        </PrimaryHeading>
        <SubHeading>
          Select a mini carbon calculator to discover the carbon emissions from
          everyday activities
        </SubHeading>
        <List items={sortedItems} />
      </View>
    )
  }
)
