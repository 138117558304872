import React, { memo } from 'react'
import { View, ViewProps } from 'react-native'
import { useSharedStyles } from 'styles'

export interface ListItemProps extends ViewProps {
  index: number
}

export const ListItem = memo(({ index, children, style, ...props }: ListItemProps) => {
  const { sharedStyles } = useSharedStyles()

  const _isFirst = index === 0
  const _isOdd = index % 2 !== 0

  return (
    <View
      style={[
        sharedStyles.listItem,
        _isFirst && sharedStyles.listItem__first,
        _isOdd && sharedStyles.listItem__odd,
        style
      ]}
      testID="ListItem"
      {...props}
    >
      {children}
    </View>
  )
})
