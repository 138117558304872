import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES } from 'clarity'

export const useStyles = () => {
  const { spacing, bp } = STYLES.useStyles()

  const container: ViewStyle = {
    paddingTop: spacing.xxxl,
    paddingLeft: spacing.page.padding.paddingLeft,
    paddingRight: spacing.page.padding.paddingRight,
    paddingBottom: spacing.page.padding.paddingBottom
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    container.paddingTop = spacing.xxxxl
  }

  const text: TextStyle = {
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    text.fontSize = STYLES.fontSize.s
    text.lineHeight = STYLES.lineHeight.s
  }

  const textContainer: TextStyle = {
    textAlign: 'center',
    marginBottom: 0
  }

  const links: ViewStyle = {
    marginTop: spacing.normal
  }

  const styles = StyleSheet.create({
    container,
    text,
    textContainer,
    links
  })

  return { styles }
}
