import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { EVLifeWidget } from 'widgets'

export const EVLifeScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title:
          'Giki Comparisons - Lifetime carbon savings from owning an Electric Vehicle'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Every time you drive an EV you save carbon compared to a petrol and diesel car. But they also take more carbon to make. Use this mini carbon calculator compare the lifetime carbon footprints of 2 cars and see how much you can save by going EV."
          />
        </Helmet>
        <Column.Container testID="EVLifeScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>
              Lifetime carbon savings from owning an Electric Vehicle
            </PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/car-clouds.svg')}
                style={{
                  width: 256,
                  height: 208
                }}
              />
            </Column.HiddenContent>
            <EVLifeWidget
              intro="Every time you drive an EV you save carbon compared to a petrol and diesel car. But they also take more carbon to make. Use this mini carbon calculator to compare the lifetime carbon footprints of 2 cars and see how much you can save by going EV."
              result="Over the life of your car you'll save **${co2eTotal}kg** of carbon emissions!\n\nThat's the same as travelling **${trainMiles} miles** by train or **${shortHaulFlightCount}** short haul flights.\n\n<small>*The running cost of electric cars are lower too. EVs help cut carbon but walking and cycling are always the lowest carbon options!*</small>"
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/car-clouds.svg')}
                style={{
                  width: 378,
                  height: 307
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text="Sign up to Giki Zero to learn more about what you can do for the planet."
              widget="ev_life_carbon_checker"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Estimates for the life cycle emissions from an electric car are calculated using Defra car emissions factors, Defra UK grid electricity factors, battery CO2e / kwh from the ICCT report on the life cycle emissions of ICE and EV cars. Estimats for petrol and diesel cars are calcuated using Defra car emissions factors, carboncounter.com estimates of embedded CO2 from manufacture for a selection of cars. For EV and ICE cars total lifetime mileage is taken from Wikipedia. Comparisons to train travel and short haul flights are based on Defra conversion factors.\n\nDisclaimer: This EV mini carbon calculaor is to help people learn more about carbon emissions from EV and ICE cars. However, all carbon footprint estimations are exactly that, estimates.We hope that in the long term companies wlil provide lifetime carbon emissions for all cars to help individuals make informed choices. This min carbon calculator was made in April 2023 with data from that time. This mini carbon calculator is provided on a fair use basis, if you chose to share the information please do citi Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
