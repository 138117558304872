import React, { useEffect, useState, useRef } from 'react'
import { StatusBar } from 'expo-status-bar'
import { Platform, LogBox } from 'react-native'
import { SafeAreaProvider, UseWindowDimensionsProvider } from 'clarity'
import * as WebBrowser from 'expo-web-browser'
import * as SplashScreen from 'expo-splash-screen'
import {
  Poppins_400Regular,
  Poppins_400Regular_Italic,
  Poppins_600SemiBold,
  Poppins_700Bold
} from '@expo-google-fonts/poppins'
import * as Font from 'expo-font'
import { Navigation } from 'navigation'

// turn off require cycle warnings caused by top-level components import
LogBox?.ignoreLogs(['Require cycle:'])

WebBrowser.maybeCompleteAuthSession()

SplashScreen.preventAutoHideAsync()

export default function App() {
  const _mountedRef = useRef(false)
  const [_isFontsLoaded, _setIsFontsLoaded] = useState(Platform.OS === 'web')

  const _loadFonts = async () => {
    await Font.loadAsync({
      Poppins_400Regular,
      Poppins_400Regular_Italic,
      Poppins_600SemiBold,
      Poppins_700Bold
    }).then(() => {
      if (_mountedRef.current) {
        _setIsFontsLoaded(true)
      }
    })
  }

  useEffect(() => {
    if (!_isFontsLoaded) return
    void SplashScreen.hideAsync()
  }, [_isFontsLoaded])

  useEffect(() => {
    if (Platform.OS === 'web') {
      const _style = document.createElement('style')
      _style.innerHTML = `@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,400&display=swap');`
      document.head.appendChild(_style)
    } else {
      _loadFonts()
    }

    _mountedRef.current = true

    return () => {
      _mountedRef.current = false
    }
  }, [])

  if (!_isFontsLoaded) return null

  return (
    <SafeAreaProvider>
      <UseWindowDimensionsProvider>
        <StatusBar style="dark" />
        <Navigation />
      </UseWindowDimensionsProvider>
    </SafeAreaProvider>
  )
}
