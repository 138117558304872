import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { STYLES, getREM } from 'clarity'

export const useStyles = () => {
  const insets = useSafeAreaInsets()
  const { COLORS, spacing, sizing, bp } = STYLES.useStyles()

  const container: ViewStyle = {
    paddingTop: insets.top,
    paddingLeft: spacing.page.padding.paddingLeft,
    paddingRight: spacing.page.padding.paddingRight,
    marginHorizontal: 'auto',
    width: '100%',
    height: sizing.topBarHeight + insets.top,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99
  }

  const background: ViewStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: COLORS.white
  }

  const topBar: ViewStyle = {
    height: sizing.topBarHeight,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: STYLES.grid.maxContentWidth,
    width: '100%',
    alignSelf: 'center'
  }

  const backButton: ViewStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    justifyContent: 'center'
  }

  const logo: ViewStyle = {
    width: 88,
    height: 34
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    logo.width = 140
    logo.height = 54
  }

  const logoLink: TextStyle = {
    borderRadius: getREM(0.125)
  }

  const styles = StyleSheet.create({
    container,
    background,
    topBar,
    backButton,
    logo,
    logoLink
  })

  return { styles }
}
