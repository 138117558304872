import { useMemo } from 'react'
import EF from 'data/emission_factors.json'

const _category = 'Transport'
const _subCategory = 'Car'
const _emissionsFactorsUnit = 'miles'

const ICETypes = ['Petrol', 'Diesel', 'Hybrid']
const EVTypes = ['Battery Electric Vehicle']

const _group = {
  'Small car': 'Small',
  'Medium car': 'Medium',
  'Large car': 'Large',
  'Luxury': 'Luxury',
  'Sports': 'Sports',
  'Dual purpose 4X4': '4WD',
  'MPV': 'MPV'
}

export const distanceDriven = 200000

export const co2eCar = 5929

export const fuelOptions = ICETypes.map((item) => ({
  label: item,
  value: item
}))

export const sizeOptions = Object.keys(_group).map((key) => ({
  label: _group[key as keyof typeof _group],
  value: key
}))

const _sharedFilters = (item: typeof EF[keyof typeof EF]) => {
  return item.category === _category &&
    item.sub_category === _subCategory &&
    item.emissions_factors_unit === _emissionsFactorsUnit &&
    Object.keys(_group).includes(item.group)
}

export const getICEData = () => {
  return Object.values(EF).filter((item) => {
    return _sharedFilters(item) &&
      ICETypes.includes(item.sub_group)
  }).map((item) => ({
    size: item.group,
    fuel: item.sub_group,
    factor: item.conversion_factor,
    key: item.key
  }))
}

export const getEVData = () => {
  return Object.values(EF).filter((item) => {
    return _sharedFilters(item) &&
      EVTypes.includes(item.sub_group)
  }).map((item) => ({
    size: item.group,
    fuel: item.sub_group,
    factor: item.conversion_factor,
    key: item.key
  }))
}

export const useData = () => {
  const ICEData = useMemo(() => {
    return getICEData()
  }, [])

  const EVData = useMemo(() => {
    return getEVData()
  }, [])

  return { ICEData, EVData, sizeOptions, fuelOptions, distanceDriven, co2eCar }
}