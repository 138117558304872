import React, { memo, useState, useEffect, useCallback } from 'react'
import { Text, View } from 'react-native'
import { Result, SubHeading, Intro, ListItem } from 'components'
import { FieldSet, NumberInput } from 'clarity'
import {
  InterpolateString,
  FormatNumber,
  GetCo2e,
  GetTVHours,
  GetFridgeYears,
  FactorUnits
} from 'utils'
import { data } from './data'
import { useSharedStyles } from 'styles'
import { WidgetProps } from '../index'

export interface LitterWidgetProps extends WidgetProps {
  hasDisposal?: boolean
  hasDays?: boolean
  excludeItems?: string[]
}

export const LitterWidget = memo(
  ({
    heading,
    intro,
    legend = '',
    result,
    hasDisposal = false,
    hasDays = false,
    excludeItems = []
  }: LitterWidgetProps) => {
    const { sharedStyles } = useSharedStyles()

    const [_counter, _setCounter] = useState<{ [key: string]: number }>(
      Object.keys(data).reduce((p, i) => ({ ...p, [i]: 0 }), {})
    )
    const [_days, _setDays] = useState(hasDays ? 7 : 1)
    const [_result, _setResult] = useState('')

    const _updateResult = useCallback(() => {
      const _totalCount = Object.values(_counter).reduce((p, i) => p + i, 0)

      const _saving = Object.keys(_counter).reduce((p, item) => {
        const _new = GetCo2e({
          factor: data[item].ef_primary,
          weight_g: data[item].weight_g,
          count: _counter[item],
          unit: FactorUnits.tonnes
        })

        const _recycled = GetCo2e({
          factor: data[item].ef_closed_loop,
          weight_g: data[item].weight_g,
          count: _counter[item],
          unit: FactorUnits.tonnes
        })

        let _disposal = 0

        if (hasDisposal) {
          _disposal = GetCo2e({
            factor: data[item].ef_waste,
            weight_g: data[item].weight_g,
            count: _counter[item],
            unit: FactorUnits.tonnes
          })
        }

        const _total = _new - _recycled + _disposal

        return p + _total
      }, 0)

      _setResult(
        InterpolateString(result, {
          total: FormatNumber(_totalCount),
          emissionsSaved: FormatNumber(_saving),
          tvHours: GetTVHours(_saving / _days),
          fridgeYears: GetFridgeYears(_saving / _days)
        })
      )
    }, [_counter, _days, hasDisposal, result])

    useEffect(() => {
      _updateResult()
    }, [_counter, _days, _updateResult])

    return (
      <View testID="LitterWidget" style={{ width: '100%' }}>
        {!!heading && <SubHeading>{heading}</SubHeading>}
        {!!intro && <Intro>{intro}</Intro>}
        <FieldSet legend={legend}>
          {!!legend && <Intro>{legend}</Intro>}
          {Object.keys(data)
            .filter((key) => !excludeItems.includes(key))
            .map((key, index) => {
              return (
                <ListItem key={key} index={index}>
                  <Text style={sharedStyles.listLabel}>{data[key].label}</Text>
                  <NumberInput
                    label={data[key].label}
                    hideLabel={true}
                    value={_counter[key]}
                    onChange={(newValue) => {
                      _setCounter((prevValue) => ({
                        ...prevValue,
                        [key]: newValue
                      }))
                    }}
                    fieldStyle={sharedStyles.listNumberField}
                    inputStyle={sharedStyles.listNumberInput}
                    style={sharedStyles.listNumber}
                  />
                </ListItem>
              )
            })}
        </FieldSet>
        {hasDays && (
          <NumberInput
            label="How many days is this for?"
            value={_days}
            onChange={_setDays}
            min={1}
            fieldStyle={sharedStyles.singleFieldBox}
          />
        )}
        {!!_result && <Result>{_result}</Result>}
      </View>
    )
  }
)
