import { StyleSheet } from 'react-native'
import { STYLES, getREM } from 'clarity'

export const useStyles = () => {
  const { COLORS } = STYLES.useStyles()

  const styles = StyleSheet.create({
    button: {
      backgroundColor: COLORS.aubergine,
      width: getREM(2.5),
      height: getREM(2.5),
      borderRadius: getREM(2.5),
      borderWidth: getREM(0.5),
      borderStyle: 'solid',
      borderColor: COLORS.white
    },
    buttonContainer: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    icon: {
      width: getREM(0.5),
      height: getREM(0.5),
      marginHorizontal: 'auto'
    }
  })

  return { styles }
}
