import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useCallback,
  ComponentProps,
  useRef
} from 'react'
import { Platform, ViewProps, View, Dimensions } from 'react-native'
import { useStyles } from './styles'
import { Dropdown } from 'react-native-element-dropdown'
import { Field, STYLES, Icons } from 'clarity'

interface ItemProps {
  value?: string
  id?: string
  label: string
}

interface DataProps {
  value: string
  label: string
}

export interface ComboboxProps extends ComponentProps<typeof Field> {
  items: ItemProps[]
  label: string
  onSelect: (value: string) => void
  disabled?: boolean
  defaultValue?: string
}

export const Combobox = memo(
  ({
    items,
    label,
    onSelect,
    disabled = false,
    defaultValue = '',
    ...props
  }: ComboboxProps) => {
    const { styles } = useStyles()
    const { focused, COLORS, input } = STYLES.useStyles()
    const { outlineColor, outlineWidth, outlineStyle } = focused as {
      outlineColor: string
      outlineWidth: string | number
      outlineStyle: string
    }
    const [value, setValue] = useState(defaultValue)
    const [isFocus, setIsFocus] = useState(false)
    const [_dropdownPosition, _setDropdownPosition] =
      useState<ComponentProps<typeof Dropdown>['dropdownPosition']>('bottom')
    const _containerRef = useRef<View>(null)
    const _flatListProps: ViewProps = {}

    _flatListProps.onLayout = ({ nativeEvent: { layout } }) => {
      _containerRef.current?.measure(
        (_x, _y, _width, height, _pageX, pageY) => {
          const _containerBottom = pageY + height
          const _listMargin = 24
          const _buffer = 20
          const _listBottom =
            _containerBottom + _listMargin + layout.height + _buffer

          if (_listBottom >= Dimensions.get('window').height) {
            _setDropdownPosition('top')
          } else {
            _setDropdownPosition('bottom')
          }
        }
      )
    }

    const data: DataProps[] = useMemo(() => {
      return items.map((item) => ({
        value: item.value || item.id || '',
        label: item.label
      }))
    }, [items])

    const _webInit = useCallback(() => {
      if (document.getElementById('Combobox_styles') !== null) return

      const _style = document.createElement('style')

      _style.id = 'Combobox_styles'

      // styling to fix focus styles on web
      _style.appendChild(
        document.createTextNode(`
          [data-testid="Combobox"] [data-testid="Label"] + div:focus-within,
          div[tabindex="0"]:has([data-testid="ComboboxList flatlist"]):focus-visible,
          div[tabindex="0"]:has([data-testid="ComboboxList flatlist"]) > [tabindex="0"]:focus-within,
          [data-testid="ComboboxList flatlist"] [tabindex="0"]:focus-visible {
            outline-color: ${outlineColor};
            outline-width: ${outlineWidth};
            outline-style: ${outlineStyle};
            border-radius: 1.25rem;
          }

          [data-testid="ComboboxList flatlist"] [tabindex="0"]:focus-visible {
            outline-offset: -8px;
          }

          [data-testid="ComboboxList"]:focus-visible,
          div[tabindex="0"]:has([data-testid="ComboboxList flatlist"]) > [tabindex="0"] input:focus-visible {
            outline: 0;
          }
        `)
      )

      // styling to increase touch target of the dropdown
      _style.appendChild(
        document.createTextNode(`
          [data-testid="ComboboxList"] {
            height: ${input.height};
            padding-left: ${input.paddingLeft};
            padding-right: ${input.paddingRight};
          }
        `)
      )

      document.head.appendChild(_style)
    }, [
      input.height,
      input.paddingLeft,
      input.paddingRight,
      outlineColor,
      outlineStyle,
      outlineWidth
    ])

    useEffect(() => {
      if (Platform.OS === 'web') {
        _webInit()
      }
    }, [_webInit])

    return (
      <View ref={_containerRef}>
        <Field testID="Combobox" label={label} {...props}>
          <Dropdown
            style={[styles.dropdown, isFocus && focused]}
            selectedTextStyle={styles.selectedTextStyle}
            inputSearchStyle={styles.inputSearchStyle}
            containerStyle={styles.list}
            itemTextStyle={styles.listItem}
            data={data}
            search={data.length > 20}
            maxHeight={300}
            labelField="label"
            valueField="value"
            placeholder=""
            searchPlaceholder="Search"
            value={value}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={(item) => {
              setValue(item.value)
              setIsFocus(false)
              onSelect(item.value)
            }}
            disable={disabled}
            renderRightIcon={() => (
              <Icons.DownChevron color={COLORS.aubergine} />
            )}
            testID="ComboboxList"
            activeColor={COLORS.mischka}
            flatListProps={_flatListProps}
            dropdownPosition={_dropdownPosition}
            inverted={false}
          />
        </Field>
      </View>
    )
  }
)
