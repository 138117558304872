import React, { memo } from 'react'
import { BackButton, Logo } from 'components'
import { Animated } from 'react-native'
import { useStyles } from './styles'
import { View, Button, ButtonType } from 'clarity'

export interface TopBarProps {
  showBackButton?: boolean
  offsetY?: Animated.Value
}

export const TopBar = memo(
  ({ showBackButton = true, offsetY = new Animated.Value(0) }: TopBarProps) => {
    const { styles } = useStyles()

    return (
      <View style={styles.container} testID="TopBar">
        <Animated.View
          style={[
            styles.background,
            {
              opacity: offsetY.interpolate({
                inputRange: [0, 100],
                outputRange: [0, 1],
                extrapolate: 'clamp'
              })
            }
          ]}
        />
        <View style={styles.topBar}>
          <View style={styles.backButton}>
            {showBackButton && <BackButton />}
          </View>
          <Button
            type={ButtonType.blank}
            textStyle={styles.logoLink}
            href="https://giki.earth/?utm_source=header_logo&utm_campaign=widgets">
            <Logo style={styles.logo} testID="TopBarLogo" />
          </Button>
        </View>
      </View>
    )
  }
)
