import React, { memo, useState, useEffect, useCallback } from 'react'
import { Text, View } from 'react-native'
import { data, waterData } from './data'
import { useSharedStyles } from 'styles'
import { SubHeading, Intro, Result, ListItem } from 'components'
import { FieldSet, NumberInput } from 'clarity'
import { FormatNumber, GetCo2e, GetDrivingEVMiles, GetShowerMonths, InterpolateString } from 'utils'
import { WidgetProps } from '../index'

export const ClothingWidget = memo(({ heading, intro, legend = '', result }: WidgetProps) => {
  const { sharedStyles } = useSharedStyles()

  const [_result, _setResult] = useState('')
  const [_counter, _setCounter] = useState<{ [key: string]: number }>(
    data.reduce((p, i) => ({ ...p, [i.emissions_factor_unique_name]: 0 }), {})
  )

  const _updateResult = useCallback(() => {
    const _emissions = Object.keys(_counter).reduce((total, key) => {
      const _item = data.find((item) => item.emissions_factor_unique_name === key)

      if (!_item) return total

      return total + GetCo2e({ count: _counter[key], factor: _item.conversion_factor })
    }, 0)

    const _water_l = Object.keys(_counter).reduce((total, key) => {
      const _item = data.find((item) => item.emissions_factor_unique_name === key)
      const _waterItem = waterData[_item?.key as keyof typeof waterData]

      if (!_item || !_waterItem) return total

      return total + (_counter[key] * _waterItem.water_l)
    }, 0)

    const _evDrivingMiles = GetDrivingEVMiles(_emissions)
    const _showersMonths = GetShowerMonths(_water_l)

    const _emissions2ndHand = _emissions / 2

    _setResult(
      InterpolateString(result, {
        emissions: FormatNumber(_emissions),
        water_l: FormatNumber(_water_l, 0),
        showersMonths: FormatNumber(_showersMonths, 0),
        evDrivingMiles: FormatNumber(_evDrivingMiles, 0),
        emissions2ndHand: FormatNumber(_emissions2ndHand)
      })
    )
  }, [_counter, result])

  useEffect(() => {
    _updateResult()
  }, [_counter, _updateResult])

  return (
    <View testID="ClothingWidget" style={{ width: '100%' }}>
      {!!heading && <SubHeading>{heading}</SubHeading>}
      {!!intro && <Intro>{intro}</Intro>}
      <FieldSet legend={legend}>
        {!!legend && <Intro>{legend}</Intro>}
        {data.map((item, index) => (
          <ListItem key={index} index={index}>
            <Text style={sharedStyles.listLabel}>{item.sub_group}</Text>
            <NumberInput
              label={item.sub_group}
              hideLabel={true}
              value={_counter[item.emissions_factor_unique_name]}
              onChange={(newValue) => {
                _setCounter((prevValue) => ({
                  ...prevValue,
                  [item.emissions_factor_unique_name]: newValue
                }))
              }}
              fieldStyle={sharedStyles.listNumberField}
              inputStyle={sharedStyles.listNumberInput}
              style={sharedStyles.listNumber}
            />
          </ListItem>
        ))}
      </FieldSet>
      <Result>{_result}</Result>
    </View>
  )
})
