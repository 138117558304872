import React, { memo, useState, useEffect, useCallback } from 'react'
import { View } from 'react-native'
import { WidgetProps } from '../index'
import { useSharedStyles } from 'styles'
import { SubHeading, Intro, Result, Combobox, RemoveButton } from 'components'
import { GetNumericStringValue, FormatNumber, InterpolateString } from 'utils'
import { Button, ButtonType, GenerateId, FieldSet, TextInput, NumberInput } from 'clarity'
import { useData, UnitOptions } from './data'

interface ItemProps {
  id: string
  type: string
  distance: string
  unit: string
}

class Item implements ItemProps {
  id: string
  type = ''
  distance = ''
  unit = ''

  constructor() {
    this.id = GenerateId()
  }
}

export const CommutingWidget = memo(({ heading, intro, legend = '', result }: WidgetProps) => {
  const { sharedStyles, spacer } = useSharedStyles()
  const [_result, _setResult] = useState('')
  const [_items, _setItems] = useState<ItemProps[]>([new Item()])
  const [_days, _setDays] = useState(1)
  const { data, options } = useData()

  const _addItem = () => {
    _setItems((prevState) => ([
      ...prevState,
      new Item()
    ]))
  }

  const _removeItem = (id: string) => {
    _setItems((prevState) => {
      const newState = [...prevState]

      for (let i = newState.length - 1; i >= 0; i--) {
        if (newState[i].id === id) {
          newState.splice(i, 1)
          break
        }
      }

      return newState
    })
  }

  const _updateItem = (id: string, field: keyof ItemProps, value: string) => {
    _setItems((prevState) => {
      const newState = [...prevState]

      for (let i = 0; i < newState.length; i++) {
        if (newState[i].id === id) {
          newState[i][field] = field === 'distance' ? GetNumericStringValue(value, newState[i][field]) : value
          break
        }
      }

      return newState
    })
  }

  const _updateResult = useCallback(() => {
    let _co2eTotal = 0

    _items.forEach((item) => {
      const _type = item.type
      const _distance = parseFloat(item.distance ?? '0')
      const _unit = parseFloat(item.unit ?? '0')

      if (!_type || !_distance || !_unit) return

      const factor = data[_type].factor
      // calculate distance to include return commute as well
      const _distance_km = (_distance * _unit) * 2
      const _co2e = factor * _distance_km

      _co2eTotal += _co2e
    })

    const _co2eTotalYear = _co2eTotal * (_days * 52)

    _setResult(InterpolateString(result, {
      co2eTotal: FormatNumber(_co2eTotal, 1),
      co2eTotalYear: FormatNumber(_co2eTotalYear, 0)
    }))
  }, [_days, _items, data, result])

  useEffect(() => {
    _updateResult()
  }, [_items, _days, _updateResult])

  return (
    <View testID="CommutingWidget" style={{ width: '100%' }}>
      {!!heading && <SubHeading>{heading}</SubHeading>}
      {!!intro && <Intro>{intro}</Intro>}
      {_items.map((item, index) => (
        <FieldSet legend={legend} key={item.id} style={[sharedStyles.box, { marginBottom: spacer }]}>
          <Combobox
            items={options}
            label="Transport type"
            onSelect={(value: string) => {
              _updateItem(item.id, 'type', value)
            }}
            testID="CommutingStageType"
          />
          <TextInput
            label="Distance"
            onChangeText={(value: string) => {
              _updateItem(item.id, 'distance', value)
            }}
            type="numeric"
            value={item.distance}
          />
          <Combobox
            items={UnitOptions}
            label="Units"
            onSelect={(value: string) => {
              _updateItem(item.id, 'unit', value)
            }}
            testID="CommutingStageUnit"
            style={{ marginBottom: 0 }}
          />
          {index !== 0 && (
            <RemoveButton onPress={() => {
              _removeItem(item.id)
            }} />
          )}
        </FieldSet>
      ))}
      <Button
        label="Add stage"
        type={ButtonType.brand}
        onPress={_addItem}
      />
      <NumberInput
        label="How many days do you commute every week?"
        value={_days}
        onChange={_setDays}
        min={1}
        fieldStyle={sharedStyles.singleFieldBox}
      />
      {!!_result && <Result>{_result}</Result>}
    </View>
  )
})
