import { useMemo } from 'react'
import EF from 'data/emission_factors.json'
import FoodWasteData from 'data/food_waste.json'
import { GetOptions, DataProps } from 'utils'

export const getData: () => DataProps = () => {
  return Object.keys(FoodWasteData).reduce((p, i) => {
    const item = FoodWasteData[i as keyof typeof FoodWasteData]

    return {
      ...p,
      [item.key]: {
        label: item.sub_group,
        id: item.key,
        factor: item.conversion_factor
      }
    }
  }, {})
}

const DisposalData = [
  EF.waste_disposal_organic_food_and_drink_waste_composting_tonnes_kg_co2e,
  EF.waste_disposal_organic_food_and_drink_waste_landfill_tonnes_kg_co2e,
  EF.waste_disposal_organic_food_and_drink_waste_home_collection_tonnes_kg_co2e
]

export const getDisposalData: () => DataProps = () => {
  return DisposalData.reduce((p, item) => {
    return {
      ...p,
      [item.key]: {
        label: item.sub_group,
        id: item.key,
        factor: item.conversion_factor
      }
    }
  }, {})
}

export const UnitOptions = [
  { value: '1', label: 'Grams (g)' },
  { value: '80', label: 'Handfuls' },
  { value: '250', label: 'Plates' },
  { value: '80', label: 'Portions' }
]

export const useData = () => {
  const data: DataProps = useMemo(() => {
    return getData()
  }, [])

  const options = useMemo(() => {
    return GetOptions(data)
  }, [data])

  const disposalData = useMemo(() => {
    return getDisposalData()
  }, [])

  const disposalOptions = useMemo(() => {
    return GetOptions(disposalData)
  }, [disposalData])

  return { data, options, disposalData, disposalOptions }
}

export const FemaleOrangutanWeight = 25