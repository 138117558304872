import { STYLES } from 'clarity'
import { StyleSheet, ViewStyle } from 'react-native'
import { useBoxStyles } from './useBoxStyles'
import { useListStyles } from './useListStyles'

export const useSharedStyles = () => {
  const { bp, spacing } = STYLES.useStyles()
  const boxStyles = useBoxStyles()
  const listStyles = useListStyles()

  let spacer = spacing.m

  if (bp.tablet) {
    spacer = spacing.xl
  }

  if (bp.desktop) {
    spacer = spacing.xxl
  }

  const singleFieldBox: ViewStyle = {
    ...boxStyles.box,
    marginBottom: 0,
    marginTop: spacer
  }

  const sharedStyles = StyleSheet.create({
    ...boxStyles,
    ...listStyles,
    singleFieldBox
  })

  return { spacer, sharedStyles }
}
