import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { LitterWidget } from 'widgets'
import { useSharedStyles } from 'styles'

export const LitterPickerScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - Litter Picker Carbon Calculator'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="How much carbon are you saving when you pick up litter? Use our mini carbon calculator to find out."
          />
        </Helmet>
        <Column.Container testID="LitterPickerScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>Litter Picker Carbon Calculator</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/keyman-clouds.svg')}
                style={{
                  width: 256,
                  height: 202
                }}
              />
            </Column.HiddenContent>
            <LitterWidget
              heading="How much carbon are you saving when you litter pick?"
              legend="Enter the number of items you've picked up and recycled to find out."
              result="You've recycled **${total} items** and saved an incredible **${emissionsSaved}kg** of carbon emissions! That's enough to watch TV for **${tvHours} hours!**\n\n<small>*Litter picking is also protecting animals from eating or getting caught in waste.*</small>"
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/keyman-clouds.svg')}
                style={{
                  width: 378,
                  height: 299
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text="You can sign up to Giki Zero to learn more about what you can do for the planet."
              widget="litter_carbon_calculator"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Carbon savings from recycling are taken from Defra conversion factors for metal, glass and plastic based on closed loop material source for new products. Conversion to TV time is based on a 55 inch LED TV using UK grid factor.\n\nDisclaimer: This litter picker widget is to help people learn more about how litter picking, with recycling, are important actions for a lower carbon planet.However, all carbon footprint estimations are exactly that, estimates and it's intended for illustrative purposes only. This widget was made in March 2023 with data from that time. This widget is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
