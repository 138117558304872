export const NavigationConfig = {
  screens: {
    HomeScreen: '',
    NotFoundScreen: '*',
    FoodWasteScreen: 'giki-food-waste-carbon-calculator',
    HolidayFlightScreen: 'giki-holiday-flight-carbon-checker',
    LitterPickerScreen: 'giki-litter-picker-carbon-calculator',
    NewClothesScreen: 'giki-new-clothes-carbon-calculator',
    RecipeScreen: 'giki-recipe-carbon-calculator',
    WorkFlightScreen: 'giki-work-flight-carbon-checker',
    RecyclingScreen: 'giki-recycling-carbon-calculator',
    SecondHandClothesScreen: 'giki-second-hand-clothes-carbon-calculator',
    LightsOffScreen: 'giki-lights-off-carbon-calculator',
    CommutingScreen: 'giki-commuting-carbon-calculator',
    EVLifeScreen: 'giki-ev-life-carbon-calculator',
    WorkHomeScreen: 'giki-work-from-home-calculator'
  }
}
