import React, { memo } from 'react'
import { Text, View } from 'react-native'
import { useStyles } from './styles'
import { Button, ButtonType, ButtonSize, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'

export interface ListProps {
  items: {
    label: string
    href: string
    subLabel?: string
  }[]
}

export const List = memo(({ items }: ListProps) => {
  const { styles } = useStyles()
  const { bp } = STYLES.useStyles()
  const { sharedStyles } = useSharedStyles()

  return (
    <View style={styles.container} testID="List">
      {items?.map((item, index) => {
        const _isFirst = index === 0
        const _isOdd = index % 2 !== 0

        return (
          <View
            key={index}
            style={[
              sharedStyles.listItem,
              _isFirst && sharedStyles.listItem__first,
              _isOdd && sharedStyles.listItem__odd
            ]}>
            <View style={styles.labelContainer}>
              <Text style={styles.text}>{item.label}</Text>
              {item.subLabel && (
                <Text style={styles.subLabel}>{item.subLabel}</Text>
              )}
            </View>
            <Button
              type={ButtonType.brand}
              isFake
              label="View"
              size={
                bp.is([bp.tablet, bp.desktop])
                  ? ButtonSize.normal
                  : ButtonSize.small
              }
            />
            <Button
              containerStyle={styles.linkContainer}
              nativeContainerStyle={styles.link}
              textStyle={styles.link}
              hideLabel
              label={item.label}
              type={ButtonType.blank}
              href={item.href}
            />
          </View>
        )
      })}
    </View>
  )
})
