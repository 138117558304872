import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { CommutingWidget } from 'widgets'

export const CommutingScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - Commuting Carbon Calculator'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Find out the carbon emissions from commuting to work."
          />
        </Helmet>
        <Column.Container testID="CommutingScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>Commuting Carbon Calculator</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/car-clouds.svg')}
                style={{
                  width: 256,
                  height: 208
                }}
              />
            </Column.HiddenContent>
            <CommutingWidget
              heading="Find out the carbon emissions from commuting to work."
              intro="Enter each stage of your commute, one way only."
              legend="Commuting stage"
              result="Each commute creates **${co2eTotal}kg** of carbon. Over the year that adds up to **${co2eTotalYear}kg** of carbon emissions.\n\n<small>*Walking and cycling are best. Then public transport followed by an EV and finally a petrol or diesel car. If your commute includes a flight then probably best to just stop doing it.*</small>"
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/car-clouds.svg')}
                style={{
                  width: 378,
                  height: 307
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text="Find out about your whole travel footprint, and much more, at Giki Zero."
              widget="commuting_carbon_calculator"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Emissions factors come from Defra 2022. By entering distance one way we then calculate the return commute. Estimates for the year are based on assumption of same level of commute, each week, for the year.\n\nDisclaimer: This communting widget is to help people learn more about how everyday travel decisions may have an impact on the planet. However, all carbon footprint estimates are exactly that, estimates. We hope that in the long term you'll be able to find out the carbon footprint of every journey you make and there will be affordable, accessible low carbon alternatives. This mini carbon calculator was made in April 2023 with data from that time. This mini carbon calculator is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
