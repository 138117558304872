export const InterpolateString = (
  string: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any }
) => {
  return new Function(
    '',
    `return \`${string.replace(/\${([^{}]*)}/g, (match, key) => {
      const _replacement = params[key] || ''

      return typeof _replacement === 'string' ||
        typeof _replacement === 'number'
        ? _replacement?.toString()
        : match
    })}\``
  )()
}
