import React, { memo, useState, useEffect, useCallback } from 'react'
import { View } from 'react-native'
import { WidgetProps } from '../index'
import { SubHeading, Intro, Result, Combobox, RemoveButton } from 'components'
import { Button, ButtonType, GenerateId, FieldSet, TextInput, NumberInput } from 'clarity'
import { useSharedStyles } from 'styles'
import { GetNumericStringValue, FormatNumber, InterpolateString, GetCo2e, FactorUnits } from 'utils'
import { UnitOptions, useData, FemaleOrangutanWeight } from './data'

interface ItemProps {
  id: string
  type: string
  amount: string
  unit: string
  disposal: string
}

class Item implements ItemProps {
  id: string
  type = ''
  amount = ''
  unit = ''
  disposal = ''

  constructor() {
    this.id = GenerateId()
  }
}

export const FoodWasteWidget = memo(({ heading, intro, legend = '', result }: WidgetProps) => {
  const { sharedStyles, spacer } = useSharedStyles()
  const [_days, _setDays] = useState(1)
  const [_result, _setResult] = useState('')
  const [_items, _setItems] = useState<ItemProps[]>([new Item()])
  const { data, options, disposalData, disposalOptions } = useData()

  const _addItem = () => {
    _setItems((prevState) => ([
      ...prevState,
      new Item()
    ]))
  }

  const _removeItem = (id: string) => {
    _setItems((prevState) => {
      const newState = [...prevState]

      for (let i = newState.length - 1; i >= 0; i--) {
        if (newState[i].id === id) {
          newState.splice(i, 1)
          break
        }
      }

      return newState
    })
  }

  const _updateItem = (id: string, field: keyof ItemProps, value: string) => {
    _setItems((prevState) => {
      const newState = [...prevState]

      for (let i = 0; i < newState.length; i++) {
        if (newState[i].id === id) {
          newState[i][field] = field === 'amount' ? GetNumericStringValue(value, newState[i][field]) : value
          break
        }
      }

      return newState
    })
  }

  const _updateResult = useCallback(() => {

    let _weightTotal = 0

    let _co2eTotal = 0

    _items.forEach((item) => {
      const _type = item.type
      const _amount = parseFloat(item.amount ?? '0')
      const _unit = parseFloat(item.unit ?? '0')
      const _disposal = item.disposal

      if (!_type || !_amount || !_unit || !_disposal) return

      const factor = data[_type].factor
      const factorDisposal = disposalData[_disposal].factor
      const weight_g = _amount * _unit

      const _co2e = GetCo2e({
        factor,
        weight_g
      })

      const _co2eDisposal = GetCo2e({
        factor: factorDisposal,
        weight_g,
        unit: FactorUnits.tonnes
      })

      _weightTotal += _amount * _unit
      _co2eTotal += _co2e + _co2eDisposal
    })

    const _co2eTotalYear = (365 / _days) * (_co2eTotal)

    const _yearWeightKg = (365 / _days) * (_weightTotal / 1000)
    const femaleOrangutanCount = FormatNumber(_yearWeightKg / FemaleOrangutanWeight)

    _setResult(
      InterpolateString(result, {
        co2eTotal: FormatNumber(_co2eTotal),
        co2eTotalYear: FormatNumber(_co2eTotalYear),
        femaleOrangutanCount
      })
    )
  }, [_days, _items, data, disposalData, result])

  useEffect(() => {
    _updateResult()
  }, [_items, _days, _updateResult])

  return (
    <View testID="FoodWasteWidget" style={{ width: '100%' }}>
      {!!heading && <SubHeading>{heading}</SubHeading>}
      {!!intro && <Intro>{intro}</Intro>}
      {_items.map((item, index) => (
        <FieldSet legend={legend} key={item.id} style={[sharedStyles.box, { marginBottom: spacer }]}>
          <Combobox
            items={options}
            label="What was thrown out?"
            onSelect={(value: string) => {
              _updateItem(item.id, 'type', value)
            }}
            testID="FoodWasteType"
          />
          <TextInput
            label="Amount"
            onChangeText={(value: string) => {
              _updateItem(item.id, 'amount', value)
            }}
            type="numeric"
            value={item.amount}
          />
          <Combobox
            items={UnitOptions}
            label="Units"
            onSelect={(value: string) => {
              _updateItem(item.id, 'unit', value)
            }}
            testID="FoodWasteUnit"
          />
          <Combobox
            items={disposalOptions}
            label="How was it disposed?"
            onSelect={(value: string) => {
              _updateItem(item.id, 'disposal', value)
            }}
            testID="FoodWasteDisposal"
            style={{ marginBottom: 0 }}
          />
          {index !== 0 && (
            <RemoveButton onPress={() => {
              _removeItem(item.id)
            }} />
          )}
        </FieldSet>
      ))}
      <Button
        label="Add item"
        type={ButtonType.brand}
        onPress={_addItem}
      />
      <NumberInput
        label="How many days is this for?"
        value={_days}
        onChange={_setDays}
        min={1}
        fieldStyle={sharedStyles.singleFieldBox}
      />
      {!!_result && <Result>{_result}</Result>}
    </View>
  )
})
