import React, { memo, useState, useEffect, useCallback } from 'react'
import { View } from 'react-native'
import { WidgetProps } from '../index'
import { SubHeading, Intro, Result, Combobox } from 'components'
import { FieldSet, TextInput, Toggle, Field } from 'clarity'
import { useSharedStyles } from 'styles'
import { GetNumericStringValue, FormatNumber, InterpolateString, GetShortHaulFlightCount, GetTrainMiles, GetEVBatteryCo2e } from 'utils'
import { useData } from './data'

export const EVLifeWidget = memo(({ heading, intro, result }: WidgetProps) => {
  const { sizeOptions, fuelOptions, ICEData, EVData, distanceDriven, co2eCar } = useData()
  const { sharedStyles, spacer } = useSharedStyles()
  const [_result, _setResult] = useState('')
  const [_EVSize, _setEVSize] = useState('')
  const [_EVBatterySize, _setEVBatterySize] = useState('60')
  const [_usesRenewables, _setUsesRenewables] = useState(true)
  const [_ICESize, _setICESize] = useState('')
  const [_ICEFuel, _setICEFuel] = useState('')

  const _updateEVBatterySize = (value: string) => {
    _setEVBatterySize((prevState) => {
      return GetNumericStringValue(value, prevState)
    })
  }

  const _updateResult = useCallback(() => {
    _setResult('')

    const _EVBatterySizeNumber = parseFloat(_EVBatterySize ?? '0')

    if ([_EVSize, _ICESize, _ICEFuel].some((item) => item === '') || !_EVBatterySizeNumber) {
      return
    }

    const _ev = EVData.find((item) => item.size === _EVSize)
    const _ice = ICEData.find((item) => item.size === _ICESize && item.fuel === _ICEFuel)

    if (!_ev || !_ice) {
      return
    }

    const _evFactor = _usesRenewables ? (0.07 * 0.3) : _ev.factor
    const _iceFactor = _ice.factor

    const _co2eBattery = GetEVBatteryCo2e(_EVBatterySizeNumber)

    const _co2eEV = (_evFactor * distanceDriven) + (co2eCar + _co2eBattery)
    const _co2eICE = (_iceFactor * distanceDriven) + co2eCar

    const _co2eTotal = _co2eICE - _co2eEV
    const _trainMiles = GetTrainMiles(_co2eTotal)
    const _shortHaulFlightCount = GetShortHaulFlightCount(_co2eTotal)

    _setResult(
      InterpolateString(result, {
        co2eTotal: FormatNumber(_co2eTotal),
        trainMiles: FormatNumber(_trainMiles, 0),
        shortHaulFlightCount: FormatNumber(_shortHaulFlightCount, 0)
      })
    )
  }, [EVData, ICEData, _EVBatterySize, _EVSize, _ICEFuel, _ICESize, _usesRenewables, co2eCar, distanceDriven, result])

  useEffect(() => {
    _updateResult()
  }, [_EVSize, _EVBatterySize, _usesRenewables, _ICESize, _ICEFuel, _updateResult])

  return (
    <View testID="EVLifeWidget" style={{ width: '100%' }}>
      {!!heading && <SubHeading>{heading}</SubHeading>}
      {!!intro && <Intro>{intro}</Intro>}
      <FieldSet legend="ICE Car" style={[sharedStyles.box, { marginBottom: spacer }]}>
        <Combobox
          items={sizeOptions}
          label="Car size"
          onSelect={_setICESize}
          testID="ICESize"
        />
        <Combobox
          items={fuelOptions}
          label="Car fuel"
          onSelect={_setICEFuel}
          testID="ICEFuel"
          style={{ marginBottom: 0 }}
        />
      </FieldSet>
      <FieldSet legend="EV" style={sharedStyles.box}>
        <Combobox
          items={sizeOptions}
          label="EV size"
          onSelect={_setEVSize}
          testID="EVSize"
        />
        <TextInput
          label="Battery size (kWh)"
          onChangeText={_updateEVBatterySize}
          type="numeric"
          value={_EVBatterySize}
        />
        <Field label="Charged by renewable electricity?" style={{ marginBottom: 0 }}>
          <Toggle
            value={_usesRenewables}
            onChange={_setUsesRenewables}
            trueLabel="Yes"
            falseLabel="No"
            accessibilityLabel="Charged by renewable electricity?"
          />
        </Field>
      </FieldSet>
      {!!_result && <Result>{_result}</Result>}
    </View>
  )
})
