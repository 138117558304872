import { StyleSheet, TextStyle, ViewStyle, Platform } from 'react-native'
import { STYLES, getREM } from 'clarity'
import { useSharedStyles } from 'styles'

export const useStyles = () => {
  const { COLORS, bp, spacing } = STYLES.useStyles()
  const { spacer } = useSharedStyles()

  const container: ViewStyle = {
    alignSelf: 'center',
    backgroundColor: COLORS.snuff,
    padding: spacing.s,
    borderRadius: getREM(1),
    marginTop: spacer,
    width: '100%'
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    container.padding = spacing.xl
  }

  const content: ViewStyle = {
    marginBottom:
      Platform.OS === 'web'
        ? `calc(-1 * ${spacing.normal})`
        : -1 * spacing.normal
  }

  const textContainer: TextStyle = {
    marginBottom: spacing.normal,
    textAlign: 'center'
  }

  const text: TextStyle = {
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs,
    color: COLORS.aubergine
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    text.fontSize = STYLES.fontSize.m
    text.lineHeight = STYLES.lineHeight.m
  }

  const styles = StyleSheet.create({
    container,
    content,
    textContainer,
    text
  })

  return { styles }
}
