import React, { memo } from 'react'
import { Text, View } from 'react-native'
import { useStyles } from './styles'

export interface SubHeadingProps {
  children: React.ReactNode
}

export const SubHeading = memo(({ children }: SubHeadingProps) => {
  const { styles } = useStyles()

  return (
    <View style={styles.container} testID="SubHeading">
      <Text style={styles.text}>{children}</Text>
    </View>
  )
})
