import React, { memo, useState, useEffect, useCallback } from 'react'
import { View } from 'react-native'
import { WidgetProps } from '../index'
import { SubHeading, Intro, Result, Combobox, RemoveButton } from 'components'
import { Button, ButtonType, GenerateId, FieldSet, TextInput, NumberInput } from 'clarity'
import { useSharedStyles } from 'styles'
import { FormatNumber, InterpolateString, GetCupsOfTeaYear, GetMobileScreenHours } from 'utils'
import { useData } from './data'

interface ItemProps {
  id: string
  type: string
  amount: string
  time: string
}

class Item implements ItemProps {
  id: string
  type = ''
  amount = '1'
  time = ''

  constructor() {
    this.id = GenerateId()
  }
}

export const LightsWidget = memo(({ heading, intro, legend = '', result }: WidgetProps) => {
  const { sharedStyles, spacer } = useSharedStyles()
  const { lightBulbOptions, countryOptions } = useData()
  const [_country, _setCountry] = useState(countryOptions?.find((item) => item.label === 'UK')?.value)
  const [_result, _setResult] = useState('')
  const [_items, _setItems] = useState<ItemProps[]>([new Item()])

  const _addItem = () => {
    _setItems((prevState) => ([
      ...prevState,
      new Item()
    ]))
  }

  const _removeItem = (id: string) => {
    _setItems((prevState) => {
      const newState = [...prevState]

      for (let i = newState.length - 1; i >= 0; i--) {
        if (newState[i].id === id) {
          newState.splice(i, 1)
          break
        }
      }

      return newState
    })
  }

  const _updateItem = (id: string, field: keyof ItemProps, value: string) => {
    _setItems((prevState) => {
      const newState = [...prevState]

      for (let i = 0; i < newState.length; i++) {
        if (newState[i].id === id) {
          newState[i][field] = value
          break
        }
      }

      return newState
    })
  }

  const _updateResult = useCallback(() => {
    let _kWhTotal = 0

    _items.forEach((item) => {
      const _type = parseFloat(item.type ?? '0')
      const _amount = parseFloat(item.amount ?? '0')
      const _time = parseFloat(item.time ?? '0')

      if (!_type || !_amount || !_time) return

      const _kWh = (_type * _amount * _time) / 1000

      _kWhTotal += _kWh
    })

    const _factor = parseFloat(_country || '0')

    const _co2eTotal = (_factor / 1000) * _kWhTotal
    const _co2eTotalYear = _co2eTotal * 365
    const _cupsOfTeaYear = GetCupsOfTeaYear(_kWhTotal)
    const _mobileScreenHours = GetMobileScreenHours(_kWhTotal)

    _setResult(
      InterpolateString(result, {
        co2eTotal: FormatNumber(_co2eTotal, _kWhTotal < 0.03 ? 4 : 2),
        co2eTotalYear: FormatNumber(_co2eTotalYear, 1),
        cupsOfTeaYear: FormatNumber(_cupsOfTeaYear, 0),
        mobileScreenHours: FormatNumber(_mobileScreenHours)
      })
    )
  }, [_country, _items, result])

  useEffect(() => {
    _updateResult()
  }, [_items, _country, _updateResult])

  return (
    <View testID="LightsWidget" style={{ width: '100%' }}>
      {!!heading && <SubHeading>{heading}</SubHeading>}
      {!!intro && <Intro>{intro}</Intro>}
      {_items.map((item, index) => (
        <FieldSet legend={legend} key={item.id} style={[sharedStyles.box, { marginBottom: spacer }]}>
          <Combobox
            items={lightBulbOptions}
            label="Light bulb type"
            onSelect={(value: string) => {
              _updateItem(item.id, 'type', value)
            }}
            testID="LightBulbType"
          />
          <NumberInput
            label="Number"
            onChange={(value: number) => {
              _updateItem(item.id, 'amount', value.toString())
            }}
            value={parseFloat(item.amount)}
            min={1}
          />
          <TextInput
            label="Hours per day"
            onChangeText={(value: string) => {
              _updateItem(item.id, 'time', value)
            }}
            type="numeric"
            value={item.time}
            containerStyle={{ marginBottom: 0 }}
          />
          {index !== 0 && (
            <RemoveButton onPress={() => {
              _removeItem(item.id)
            }} />
          )}
        </FieldSet>
      ))}
      <Button
        label="Add light bulb"
        type={ButtonType.brand}
        onPress={_addItem}
      />
      <Combobox
        items={countryOptions}
        label="Choose Country"
        onSelect={_setCountry}
        testID="LightBulbCountry"
        style={sharedStyles.singleFieldBox}
        defaultValue={_country}
      />
      {!!_result && <Result>{_result}</Result>}
    </View>
  )
})
