export const GetMeanAverage = (...args: number[]) => {
  let meanAverage = 0

  if (!args) return meanAverage

  for (let i = 0; i < args.length; i++) {
    meanAverage += args[i]
  }

  return meanAverage / args.length
}