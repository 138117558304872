interface GetCo2eJourneyProps {
  factor: number
  distance: number
  isReturn?: boolean
}

export const GetCo2eJourney = ({ factor, distance, isReturn = false }: GetCo2eJourneyProps) => {
  const _distance = isReturn ? distance * 2 : distance

  return factor * _distance
}