import React, { memo } from 'react'
import { Image } from 'react-native'
import { useStyles } from './styles'
import { Button, ButtonType } from 'clarity'

export const RemoveButton = memo(
  ({
    textStyle,
    containerStyle,
    ...props
  }: React.ComponentProps<typeof Button>) => {
    const { styles } = useStyles()

    return (
      <Button
        textStyle={[styles.button, textStyle]}
        testID="RemoveButton"
        label="remove"
        type={ButtonType.blank}
        containerStyle={[styles.buttonContainer, containerStyle]}
        {...props}>
        <Image source={require('images/close-icon.svg')} style={styles.icon} />
      </Button>
    )
  }
)
