import React, { memo } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Platform } from 'react-native'
import { Button, ButtonType, Icons } from 'clarity'

export const BackButton = memo(() => {
  const navigation = useNavigation()

  const BackArrow = ({
    style,
    ...props
  }: React.ComponentProps<typeof Icons.BackArrow>) => {
    const _scale = Platform.OS === 'web' ? 0.7 : 1
    return (
      <Icons.BackArrow
        {...props}
        style={[style, { transform: [{ scale: _scale }] }]}
      />
    )
  }

  return (
    <Button
      type={ButtonType.icon}
      icon={BackArrow as typeof Icons.BackArrow}
      label="Go back to home"
      onPress={() => navigation.navigate('HomeScreen' as never)}
      hideLabel
      testID="BackButton"
    />
  )
})
