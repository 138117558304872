import EF from 'data/emission_factors.json'

interface DataProps {
  [key: string]: {
    label: string
    weight_g: number
    ef_primary: number
    ef_closed_loop: number
    ef_waste: number
  }
}

export const data: DataProps = {
  cans: {
    label: 'Cans',
    weight_g: 15,
    ef_primary:
      EF
        .metal_metal_aluminium_cans_and_foil_excl_forming_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF
        .metal_metal_aluminium_cans_and_foil_excl_forming_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF
        .waste_disposal_metal_aluminium_cans_and_foil_excl_forming_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  small_glass_bottles: {
    label: 'Small glass bottles and jars',
    weight_g: 200,
    ef_primary:
      EF.other_glass_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF.other_glass_closed_loop_source_tonnes_kg_co2e.conversion_factor,
    ef_waste: EF.waste_disposal_glass_landfill_tonnes_kg_co2e.conversion_factor
  },
  large_glass_bottles: {
    label: 'Large glass bottles',
    weight_g: 400,
    ef_primary:
      EF.other_glass_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF.other_glass_closed_loop_source_tonnes_kg_co2e.conversion_factor,
    ef_waste: EF.waste_disposal_glass_landfill_tonnes_kg_co2e.conversion_factor
  },
  metal_foil_and_trays: {
    label: 'Metal foil and trays',
    weight_g: 11,
    ef_primary:
      EF
        .metal_metal_aluminium_cans_and_foil_excl_forming_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF
        .metal_metal_aluminium_cans_and_foil_excl_forming_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF
        .waste_disposal_metal_aluminium_cans_and_foil_excl_forming_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  paper: {
    label: 'Paper, magazines, small cardboard boxes',
    weight_g: 68,
    ef_primary:
      EF.paper_paper_and_board_board_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF.paper_paper_and_board_board_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF.waste_disposal_paper_and_board_mixed_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  large_paper: {
    label: 'Large cardboard boxes',
    weight_g: 400,
    ef_primary:
      EF.paper_paper_and_board_board_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF.paper_paper_and_board_board_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF.waste_disposal_paper_and_board_mixed_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  plastic_bags: {
    label: 'Plastic bags',
    weight_g: 5,
    ef_primary:
      EF
        .plastic_plastics_ldpe_and_lldpe_incl_forming_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF
        .plastic_plastics_ldpe_and_lldpe_incl_forming_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF.waste_disposal_plastics_average_plastics_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  small_plastic_bottle: {
    label: 'Small Plastic Bottles',
    weight_g: 14,
    ef_primary:
      EF
        .plastic_plastics_average_plastics_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF.plastic_plastics_average_plastics_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF.waste_disposal_plastics_average_plastics_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  large_plastic_bottle: {
    label: 'Large Plastic Bottles',
    weight_g: 25,
    ef_primary:
      EF
        .plastic_plastics_average_plastics_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF.plastic_plastics_average_plastics_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF.waste_disposal_plastics_average_plastics_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  plastic_pots: {
    label: 'Plastic Pots and Tubs',
    weight_g: 5,
    ef_primary:
      EF
        .plastic_plastics_average_plastics_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF.plastic_plastics_average_plastics_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF.waste_disposal_plastics_average_plastics_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  plastic_trays: {
    label: 'Plastic Trays',
    weight_g: 5,
    ef_primary:
      EF
        .plastic_plastics_average_plastics_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF.plastic_plastics_average_plastics_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF.waste_disposal_plastics_average_plastics_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  tins: {
    label: 'Tins',
    weight_g: 70,
    ef_primary:
      EF.metal_metal_mixed_cans_primary_material_production_tonnes_kg_co2e
        .conversion_factor,
    ef_closed_loop:
      EF.metal_metal_mixed_cans_closed_loop_source_tonnes_kg_co2e
        .conversion_factor,
    ef_waste:
      EF
        .waste_disposal_metal_aluminium_cans_and_foil_excl_forming_landfill_tonnes_kg_co2e
        .conversion_factor
  },
  other_items: {
    label: 'Other Items (Non Recyclable)',
    weight_g: 10,
    ef_primary: 0,
    ef_closed_loop: 0,
    ef_waste:
      EF.waste_disposal_household_residual_waste_landfill_tonnes_kg_co2e
        .conversion_factor
  }
}
