import React, { memo } from 'react'
import { Text, View } from 'react-native'
import { useStyles } from './styles'

export interface IntroProps {
  children?: React.ReactNode
}

export const Intro = memo(({ children }: IntroProps) => {
  const { styles } = useStyles()

  return (
    <View testID="Intro">
      <Text style={styles.text}>{children}</Text>
    </View>
  )
})
