import { STYLES, getREM } from 'clarity'
import { ViewStyle, TextStyle } from 'react-native'

export const useListStyles = () => {
  const { bp, spacing, COLORS, fontRegular } = STYLES.useStyles()

  const listItem: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: getREM(0.0625),
    borderStyle: 'solid',
    borderBottomColor: COLORS.steam,
    minHeight: getREM(3.125),
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
    paddingTop: spacing.xs,
    paddingBottom: spacing.xs,
    backgroundColor: COLORS.white,
    position: 'relative'
  }

  if (bp.is([bp.mobile_l, bp.mobile_xl, bp.tablet, bp.desktop])) {
    listItem.paddingLeft = spacing.normal
    listItem.paddingRight = spacing.normal
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    listItem.minHeight = getREM(4.6875)
  }

  const listItem__first: ViewStyle = {
    borderTopWidth: getREM(0.0625),
    borderStyle: 'solid',
    borderTopColor: COLORS.steam
  }

  const listItem__odd: ViewStyle = {
    backgroundColor: COLORS.paper
  }

  const listLabel: TextStyle = {
    ...fontRegular,
    textTransform: 'capitalize',
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    paddingRight: spacing.xs
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    listLabel.fontSize = STYLES.fontSize.m
    listLabel.lineHeight = STYLES.lineHeight.m
  }

  const listNumberField: ViewStyle = {
    marginBottom: 0,
    width: getREM(8)
  }

  if (bp.desktop) {
    listNumberField.width = getREM(10)
  }

  const listNumber: ViewStyle = {
    borderWidth: 0,
    backgroundColor: 'none',
    borderRadius: getREM(0.25),
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0
  }

  const listNumberInput: ViewStyle = {
    backgroundColor: 'none'
  }

  return {
    listItem,
    listItem__first,
    listItem__odd,
    listLabel,
    listNumberField,
    listNumber,
    listNumberInput
  }
}