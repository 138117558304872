import { StyleSheet, TextStyle, ViewStyle, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'clarity'

export const useStyles = () => {
  const { spacing, bp, COLORS } = STYLES.useStyles()

  const buttonContainer: ViewStyle = {
    marginTop: spacing.l
  }

  const buttonGroup: ViewStyle = {
    flexDirection: 'column',
    marginTop: spacing.s,
    marginBottom: spacing.l
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    buttonGroup.flexDirection = 'row'
  }

  const storeButtonContainer: ViewStyle = {
    flexGrow: 1
  }

  const storeButton: TextStyle = {
    flex: 1,
    backgroundColor: COLORS.black,
    color: COLORS.white,
    height: getREM(3),
    borderRadius: getREM(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: spacing.l,
    paddingRight: spacing.l
  }

  const storeButtonImage: ImageStyle = {
    width: '100%',
    maxWidth: getREM(9.75),
    height: getREM(3)
  }

  const feedbackTextContainer: TextStyle = {
    marginBottom: 0
  }

  const styles = StyleSheet.create({
    buttonContainer,
    buttonGroup,
    storeButtonContainer,
    storeButton,
    storeButtonImage,
    feedbackTextContainer
  })

  return { styles }
}
