import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { FoodWasteWidget } from 'widgets'

export const FoodWasteScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - Food Waste Carbon Calculator'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="How much carbon are you wasting by throwing out food? This quick mini carbon calculator will help you find out."
          />
        </Helmet>
        <Column.Container testID="FoodWasteScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>Food Waste Carbon Calculator</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/food-clouds.svg')}
                style={{
                  width: 256,
                  height: 159
                }}
              />
            </Column.HiddenContent>
            <FoodWasteWidget
              heading="How much carbon are you wasting by throwing out food?"
              intro={`You can enter part portions or plates and use "All other food waste" if you cannot find the food you are looking for or if you want to enter a total amount rather than individual items.`}
              legend="Food waste item"
              result="Wasting this food creates **${co2eTotal}kg** of carbon emissions.\n\nOver the course of a year that's **${co2eTotalYear}kg** of carbon wasted and the wasted food would weigh the same as **${femaleOrangutanCount}** female orangutans, which are at threat of extinction due to deforestation.\n\n<small>*Carbon gets wasted by not eating edible food and methane is also emitted if it gets sent to landfill.*</small>"
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/food-clouds.svg')}
                style={{
                  width: 378,
                  height: 235
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text={`Take a step to [cut back on all food waste](https://zero.giki.earth/actions/zero-food-waste?utm_source=food_waste_carbon_calculator&utm_campaign=widgets) for a week.`}
              widget="food_waste_carbon_calculator"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Food emissions factors are taken from [Our World in Data](https://ourworldindata.org/environmental-impacts-of-food), Joseph Poore and Thomas Nemecek (2018), Giki estimates, CarbonCloud Climate Hub, The Food Footprint 100 report. Waste emissions factors are taken from Defra 2022.\n\nDisclaimer: This food waste widget is to help people learn more about how everyday food decisions may have an impact on the planet. However, all carbon footprint estimates are exactly that, estimates.We hope that in the long term companies will provide accurate carbon footprints of all the foods and meals they sell and serve on the pack or menu and that low waste disposal options will be available to most people. This widget was made in April 2023 with data from that time. This widget is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
