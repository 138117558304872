import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { FlightWidget } from 'widgets'

export const HolidayFlightScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - Holiday Flight Carbon Checker'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Discover the carbon footprint of your holiday flight quickly. Just enter where you're travelling from, and to, to find out."
          />
        </Helmet>
        <Column.Container testID="HolidayFlightScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>Holiday Flight Carbon Checker</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/flight-clouds.svg')}
                style={{
                  width: 256,
                  height: 169
                }}
              />
            </Column.HiddenContent>
            <FlightWidget
              legend="Enter your airport departure and destination to find out how much carbon will be emitted from a return flight."
              result="A return flight from ${departure} to ${destination} emits **${emissions}kg** of carbon.\n\nThat's equivalent to eating **${beefBurgerCount}** beef burgers.\n\n<small>*Offsets don't solve the problem but flying shorter distances or a little less can help cut carbon.*</small>"
              screenType="Holiday"
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/flight-clouds.svg')}
                style={{
                  width: 378,
                  height: 249
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text="Check our Giki Zero to learn what you can do for the planet including a full flight calculator."
              widget="holidy_flight_carbon_checker"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Emissions factors are taken from Defra including radiative forcing. That's where the contrails in the air left by planes add to global warming. Comparisons to a typical person are based on global personal carbon footprints. Comparisons to beef burgers use Nemeck and Poore 2018. This widget was made in March 2023 with data from that time.\n\nDisclaimer: This holiday flight carbon checker is to help people learn more about how everyday decisions and the impact they can have on the planet. However, all carbon footprint estimations are exactly that, estimates. We hope that in the long term flight companies will provide accurate carbon footprints when you book a flight. This widget is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
