import EF from 'data/emission_factors.json'
import { FormatNumber } from '../FormatNumber'

export const tv_kwh = 0.116

export const GetTVHours = (emissions: number) => {
  const _hours = emissions /
    (tv_kwh *
      EF.electricity_uk_grid_domestic_electricity_kwh_kg_co2e
        .conversion_factor)

  return FormatNumber(_hours, 0)
}
