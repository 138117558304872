import React, { memo, useState, useEffect, useMemo, useCallback } from 'react'
import { View } from 'react-native'
import { WidgetProps } from '../index'
import {
  AirportData,
  AirportDataKeyType,
  HotelEmissionsDataKeyType,
  TravelClassData
} from './data'
import { Intro, Result, Combobox, SubHeading, RemoveButton } from 'components'
import { Button, ButtonType, FieldSet, GenerateId } from 'clarity'
import { useSharedStyles } from 'styles'
import {
  InterpolateString,
  FormatNumber,
  GetFlightDistance,
  GetVirtualMeetingCount,
  GetFlightLength,
  GetCo2eJourney,
  GetHotelEmissions,
  GetBeefBurgerCount
} from 'utils'
import HotelComponent from './hotel'

interface FlightWidgetProps extends WidgetProps {
  onUpdateHotelEmissions?: (hotelEmissions: number) => void
  screenType: 'Holiday' | 'Work'
}

interface FlightItemProps {
  id: string
  departure?: string
  destination?: string
  classOfTravel?: string
}

class FlightItem implements FlightItemProps {
  id: string

  constructor() {
    this.id = GenerateId()
  }
}

interface HotelItemProps {
  id: string
  location: string
  nights: number
}

class HotelItem implements HotelItemProps {
  id: string
  location: string = ''
  nights: number = 1

  constructor() {
    this.id = GenerateId()
  }
}

export const FlightWidget = memo(
  ({ heading, intro, legend = '', result, screenType }: FlightWidgetProps) => {
    const { sharedStyles, spacer } = useSharedStyles()
    const [_result, _setResult] = useState('')
    const [_flights, _setFlights] = useState<FlightItemProps[]>([
      new FlightItem()
    ])
    const [_showHotel, _setShowHotel] = useState<boolean>(false)
    const [_hotels, _setHotels] = useState<HotelItemProps[]>([])
    const _isWork = screenType === 'Work'
    const _airportItems = useMemo(() => {
      return Object.keys(AirportData)
        .map((key) => ({
          id: key,
          label: AirportData[key as AirportDataKeyType].name
        }))
        .sort((item1, item2) => {
          if (item1.label > item2.label) return 1
          if (item1.label < item2.label) return -1
          return 0
        })
    }, [])

    const _classOfTravelItems = useMemo(() => {
      return Object.values(TravelClassData)
    }, [])

    const _updateResult = useCallback(() => {
      const isFlightDataComplete = _flights.some(
        (flight) =>
          flight.departure && flight.destination && flight.classOfTravel
      )

      if (!_flights.length || !isFlightDataComplete) {
        _setResult('')
        return
      }

      const _totalFlightEmissions = _flights.reduce((total, flight) => {
        const _departureData =
          AirportData[flight.departure as AirportDataKeyType]
        const destinationData =
          AirportData[flight.destination as AirportDataKeyType]

        if (!_departureData || !destinationData) {
          return total
        }

        const distance = GetFlightDistance({
          departure: { lat: _departureData.lat, lon: _departureData.lon },
          destination: { lat: destinationData.lat, lon: destinationData.lon }
        })

        if (!distance) {
          return total
        }

        const shortOrLongHaul = GetFlightLength(distance)
        const _emissionsFactor =
          TravelClassData[
            flight.classOfTravel as keyof typeof TravelClassData
          ]?.[shortOrLongHaul] ?? 0

        // emissions for a return journey
        const flightEmissions = GetCo2eJourney({
          factor: _emissionsFactor,
          distance,
          isReturn: true
        })

        return total + flightEmissions
      }, 0)

      const _firstDeparture =
        AirportData[_flights[0].departure as AirportDataKeyType]
      const _firstDestination =
        AirportData[_flights[0].destination as AirportDataKeyType]
      const _hotelEmissions = _hotels.reduce(
        (total, hotel) =>
          total +
          GetHotelEmissions({
            location: hotel.location as HotelEmissionsDataKeyType,
            nights: hotel.nights
          }),
        0
      )
      const _beefBurgerCount = GetBeefBurgerCount(_totalFlightEmissions)

      const _totalEmissions = _totalFlightEmissions + _hotelEmissions
      const _virtualMeetingCount = GetVirtualMeetingCount(_totalEmissions)

      _setResult(() =>
        InterpolateString(result, {
          emissions: FormatNumber(_totalEmissions, 0),
          flightEmissions: FormatNumber(_totalFlightEmissions, 0),
          hotelEmissions: FormatNumber(_hotelEmissions, 0),
          virtualMeetingCount: FormatNumber(_virtualMeetingCount, 0),
          departure: _firstDeparture.name,
          destination: _firstDestination.name,
          beefBurgerCount: FormatNumber(_beefBurgerCount, 0)
        })
      )
    }, [result, _hotels, _flights])

    const _addFlight = () => {
      _setFlights((prevFlights) => [...prevFlights, new FlightItem()])
    }

    const _removeFlight = (id: string) => {
      _setFlights((prevFlights) =>
        prevFlights.filter((flight) => flight.id !== id)
      )
    }

    const _setDepartureForFlight = (flightId: string) => (value: string) => {
      _setFlights((prevFlights) =>
        prevFlights.map((flight) =>
          flight.id === flightId ? { ...flight, departure: value } : flight
        )
      )
    }

    const _setDestinationForFlight = (flightId: string) => (value: string) => {
      _setFlights((prevFlights) =>
        prevFlights.map((flight) =>
          flight.id === flightId ? { ...flight, destination: value } : flight
        )
      )
    }

    const _setClassOfTravelForFlight =
      (flightId: string) => (value: string) => {
        _setFlights((prevFlights) =>
          prevFlights.map((flight) =>
            flight.id === flightId
              ? { ...flight, classOfTravel: value }
              : flight
          )
        )
      }

    const _addHotel = () => {
      _setHotels((prevState) => [...prevState, new HotelItem()])
    }

    const _removeHotel = (id: string) => {
      _setHotels((prevState) => prevState.filter((item) => item.id !== id))
    }

    const _updateHotel = (
      id: string,
      field: keyof HotelItemProps,
      value: string | number | undefined
    ) => {
      _setHotels((prevState) =>
        prevState.map((item) =>
          item.id === id
            ? {
                ...item,
                [field]:
                  field === 'nights'
                    ? typeof value === 'number'
                      ? value
                      : parseInt(value || '0', 10)
                    : value || ''
              }
            : item
        )
      )
      _updateResult()
    }

    useEffect(() => {
      _updateResult()
    }, [_flights, _showHotel, _hotels, _updateResult])

    const handleAddHotel = () => {
      _setShowHotel(true)
      _addHotel()
    }

    const handleRemoveHotel = (id: string) => {
      _removeHotel(id)

      if (_hotels.length === 1) {
        _setShowHotel(false)
      }
    }

    return (
      <View testID="FlightWidget" style={{ width: '100%' }}>
        {!!heading && <SubHeading>{heading}</SubHeading>}
        {!!intro && <Intro>{intro}</Intro>}
        {!!legend && <Intro>{legend}</Intro>}
        {_flights.map((flight, index) => (
          <View key={flight.id}>
            <FieldSet legend={legend}>
              <View
                style={[
                  sharedStyles.box,
                  {
                    marginBottom: _isWork ? spacer : 0
                  }
                ]}>
                <Combobox
                  items={_airportItems}
                  label="Departure Airport"
                  onSelect={_setDepartureForFlight(flight.id)}
                />
                <Combobox
                  items={_airportItems}
                  label="Destination Airport"
                  onSelect={_setDestinationForFlight(flight.id)}
                />
                <Combobox
                  items={_classOfTravelItems}
                  label="Class of travel"
                  onSelect={_setClassOfTravelForFlight(flight.id)}
                  style={{ marginBottom: 0 }}
                />
              </View>
              {index !== 0 && (
                <RemoveButton
                  containerStyle={{ top: '1px', right: '1px' }}
                  onPress={() => {
                    _removeFlight(flight.id)
                  }}
                />
              )}
            </FieldSet>
          </View>
        ))}
        {_isWork && (
          <>
            <Button
              label="Add Flight"
              type={ButtonType.brand}
              onPress={_addFlight}
            />

            <View
              style={{
                marginBottom: spacer
              }}
            />

            {_showHotel &&
              _hotels.map((hotel) => (
                <HotelComponent
                  key={hotel.id}
                  onLocationChange={(value) =>
                    _updateHotel(hotel.id, 'location', value)
                  }
                  onNightsChange={(value) =>
                    _updateHotel(hotel.id, 'nights', value)
                  }
                  onRemoveHotel={() => handleRemoveHotel(hotel.id)}
                />
              ))}
            <Button
              label="Add Hotel"
              type={ButtonType.brand}
              onPress={handleAddHotel}
            />
          </>
        )}
        {!!_result && <Result>{_result}</Result>}
      </View>
    )
  }
)
