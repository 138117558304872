import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { LightsWidget } from 'widgets'

export const LightsOffScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - Turn the lights off!'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Find out how much carbon gets wasted when you leave the lights on, and what you could do with that energy instead."
          />
        </Helmet>
        <Column.Container testID="LightsOffScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>Turn the lights off!</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/lightbulb-clouds.svg')}
                style={{
                  width: 256,
                  height: 208
                }}
              />
            </Column.HiddenContent>
            <LightsWidget
              heading="Find out how much carbon gets wasted when you leave the lights on, and what you could do with that energy instead."
              intro="Enter the lights that are left on, and for how long."
              legend="Light bulb type"
              result="By turning off lights you can save **${co2eTotal}kg** of carbon a day and **${co2eTotalYear}kg** every year.\n\nThat's enough energy saved in just one day for **${mobileScreenHours} hours** on your phone. It's also enough to make **${cupsOfTeaYear} cups of tea** a year if you do it everyday."
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/lightbulb-clouds.svg')}
                style={{
                  width: 378,
                  height: 307
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text="Discover more carbon and money savings ideas on Giki Zero."
              widget="lights_off_carbon_calculator"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Calculations are based on country grid factors from Defra and national sources.Equivalent calculations are based on the carbon footprint of charging and using a phone and the electricity need to boil a kettle.\n\nDisclaimer: This Turn the Lights off mini carbon calculator is to help people learn more about how everyday decisions may have an impact on the planet. However, all carbon footprint estimations are exactly that, estimates.Using smart meteres and assessing changes can be used to provide savings directly related to your own lighting system.This mini carbon calculator was made in March 2023 with data from that time. This mini carbon calcultor is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
