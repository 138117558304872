import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { RecipeWidget } from 'widgets'

export const RecipeScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - Recipe Carbon Calculator'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Discover the carbon footprint of your recipe. Enter ingredients, see you carbon footprint and find out which one has the heaviest carbon weight."
          />
        </Helmet>
        <Column.Container testID="RecipeScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>Recipe Carbon Calculator</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/food-clouds.svg')}
                style={{
                  width: 256,
                  height: 159
                }}
              />
            </Column.HiddenContent>
            <RecipeWidget
              intro={`Enter ingredients to find out the carbon footprint of your recipe. Use "Other" categories if you cannot find the exact ingredient you're looking for.`}
              legend="Ingredient item"
              result="The carbon footprint of this recipe is **${co2eTotal}kg**. That's the same as the carbon emissions from **${appleCount}** apples.\n\nThe ingredient with the largest carbon footprint is ${co2eLargestLabel} at ${co2eLargestPercent}%.\n\n<small>*Anything under 1kg for a meal for one person is low.*</small>"
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/food-clouds.svg')}
                style={{
                  width: 378,
                  height: 235
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text="You can sign up to Giki Zero to learn more about eating sustainably and what you can do for the planet."
              widget="recipe_carbon_calculator"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Estimates are taken from [Our World in Data](https://ourworldindata.org/environmental-impacts-of-food), Joseph Poore and Thomas Nemecek (2018), Giki estimates, CarbonCloud Climate Hub.\n\nDisclaimer: This recipe carbon footprint widget is to help people learn more about how everyday food decisions may have an impact on the planet. However, all carbon footprint estimations are exactly that, estimates. We hope that in the long term companies will provide accurate carbon footprints of all the foods and meals they sell and serve on the pack or menu. This widget was made in March 2023 with data from that time. This widget is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
