interface GetFlightDistanceProps {
  departure: {
    lat: number
    lon: number
  }
  destination: {
    lat: number
    lon: number
  }
}

export const GetFlightDistance = ({ departure, destination }: GetFlightDistanceProps) => {
  const _toRadians = (value: number) => {
    return value * Math.PI / 180
  }

  const distance = Math.round((Math.acos(Math.cos(_toRadians(90 - departure.lat)) * Math.cos(_toRadians(90 - destination.lat)) + Math.sin(_toRadians(90 - departure.lat)) * Math.sin(_toRadians(90 - destination.lat)) * Math.cos(_toRadians(departure.lon - destination.lon))) * 6371))

  return distance
}