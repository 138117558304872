import React, { memo, useEffect } from 'react'
import { Image } from 'react-native'
import { Footnote, WhatNext, PrimaryHeading } from 'components'
import { Helmet } from 'react-helmet'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Column, STYLES } from 'clarity'
import { useSharedStyles } from 'styles'
import { FlightWidget } from 'widgets'

export const WorkFlightScreen = memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ navigation }: NativeStackScreenProps<any>) => {
    const { bp } = STYLES.useStyles()
    const { spacer } = useSharedStyles()

    useEffect(() => {
      navigation.setOptions({
        title: 'Giki Comparisons - Work Trip Carbon Calculator'
      })
    }, [navigation])

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Discover the carbon footprint of your work flight and how many virtual meetings you could have with the same carbon emissions."
          />
        </Helmet>
        <Column.Container testID="WorkFlightScreen">
          <Column.Left spacer={spacer}>
            <PrimaryHeading>Work Trip Carbon Calculator</PrimaryHeading>
            <Column.HiddenContent hidden={bp.desktop}>
              <Image
                source={require('images/flight-clouds.svg')}
                style={{
                  width: 256,
                  height: 169
                }}
              />
            </Column.HiddenContent>
            <FlightWidget
              legend="Find out the carbon footprint of your flight and hotel on a work trip."
              result="Your business trip flight and hotel emits **${emissions}kg** of carbon. Flights are **${flightEmissions}kg** and hotels are **${hotelEmissions}kg**.\n\nYou could have **${virtualMeetingCount}** virtual meetings for the same carbon.\n\n<small>*Offsets don't solve the problem but flying shorter distances, taking direct flights, choosing the most efficient airlines and going on fewer but longer trips can all help reduce emissions.*</small>"
              screenType="Work"
            />
          </Column.Left>
          <Column.Right spacer={spacer}>
            <Column.HiddenContent hidden={bp.not([bp.desktop])}>
              <Image
                source={require('images/flight-clouds.svg')}
                style={{
                  width: 378,
                  height: 249
                }}
              />
            </Column.HiddenContent>
            <WhatNext
              text="Check our Giki Zero to learn what you can do for the planet including a full flight calculator."
              widget="work_flight_carbon_checker"
            />
          </Column.Right>
        </Column.Container>
        <Footnote>
          {`Methodology: Emissions factors are taken from Defra including radiative forcing for flights. That's where the contrails in the air left by planes add to global warming. Comparisons to virtual meetings are based on IEA estimates for 1 hour of streaming for 2 people. This widget was made in December 2023 with data from that time.\n\nDisclaimer: This work trip carbon checker is to help people learn more about how everyday decisions and the impact they can have on the planet. However, all carbon footprint estimations are exactly that, estimates. We hope that in the long term flight companies and hotels will provide accurate carbon footprints when you book a flight. This widget is provided on a fair use basis, if you chose to share the information please do cite Giki as the source.`}
        </Footnote>
      </>
    )
  }
)
